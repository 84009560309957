import React from 'react';
import Slider from "react-slick";
import Header from '../Header';
import Footer from '../Footer';
const About = () => {

    let settings = {
        centerMode: true,
        centerPadding: '60px',
        slidesToShow: 1,
        arrows:false,
        dots:false,
      };

    return(
        <>
        <Header />

        <section class="common-content">
            <div class="container text-center">
                <div className='content'>  
                <h2>Our Vision</h2>
                <p>Our mission is to redefine virtual experiences and empower users to explore, play, and connect in a Xverse shaped by their desires. We want to become the first virtual space where users can customize each little detail about their appearance and surroundings as possible. We will nurture a  sustainable and content creator friendly economy beneficial for everyone. Join us today on this journey.</p>

                <h2>Meet the Team</h2>
                <p>At Xverse, our strength lies in our diverse team of innovators, developers, and dreamers. United by a shared passion for pushing the boundaries of technology, we bring a wealth of expertise to create a Xverse that transcends expectations.</p>

                <h2>Our Commitment</h2>
                <p>Beyond technology, our commitment is to empower you. Xverse is more than a Xverse; it's a collaborative canvas where each click, interaction, and creation contributes to a tapestry of endless possibilities. Your journey is at the heart of our universe.</p>

                <h2>Join the Adventure Now</h2>
                <p>Come and be a part of this adventure. At Xverse, we invite you to explore a Xverse where your creativity knows no bounds. This is more than a digital realm; it's a canvas for your dreams and a celebration of infinite possibilities. Welcome to Xverse, where the extraordinary becomes your reality.</p>
                </div>
            </div>
        </section>

        <Footer />
    </>
    );
};

export default About;