import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { React, useState, useEffect } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";

import TopBar from '../TopBar';
import MainLinks from '../MainLinks';
import { web3 } from "../../store/web3";
import { toFixed } from "../../store/numberFormat";
import { setLoader } from "../../store/actions/Auth";
import { makeTokens } from "../../store/contract/index";

const logo = "/images/main-logo.png";
const arrowswap = "/images/arrow-swap.png";
const sidebararrow = "/images/sidebar-arrow.png";

const Mapping = (props) => {

    const [isActive, setIsActive] = useState(false);

    const [show, setShow] = useState(false);
    const [id, setId] = useState(0);
    const [price, setPrice] = useState(0);
    const handleClose = () => setShow(false);

    const sidebarArrow = () => {
        setIsActive(!isActive);
    };

    useEffect(() => {
        if (props.publicAddress) {
            loadData();
        }
    }, [props.publicAddress]);

    async function loadData() {
        const { LandNFT } = await makeTokens();
        let price = await LandNFT.methods.price().call();
        price = web3.utils.fromWei(price, 'ether');
        setPrice(toFixed(parseFloat(price), "price"));
    }

    async function handleShow(id) {
        setId(parseInt(id));
        // setShow(true);
        window.location.href = `/minting/${parseInt(id)}`;
    };

    async function mintToken(id) {
        try {
            const { LandNFTAddress, LandNFT } = await makeTokens();

            let { publicAddress } = props;

            if (parseInt(id) <= 0) {
                EventBus.publish("error", `Invalid Land ID!`);
                return;
            }

            if (parseInt(id) == NaN && parseInt(id) == undefined) {
                EventBus.publish("error", `Invalid Land ID!`);
                return;
            }

            let minted = await LandNFT.methods.checkMinted(id).call();

            if (minted == true) {
                EventBus.publish("error", `Land already purchased!`);
                return;
            }

            let price = await LandNFT.methods.price().call();

            if (publicAddress == null || publicAddress == undefined) {
                EventBus.publish("error", `Please connect your wallet!`);
                return;
            }

            let deployer = (await web3.currentProvider.enable())[0];

            const balanceWei = await web3.eth.getBalance(deployer);
            const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);
            if (balanceWei < price) return EventBus.publish("error", `Insufficient balance to purchase land, required: ${web3.utils.fromWei(price, 'ether')} XDC`);

            props.setLoader({
                message: "Purchase in Progress...",
                status: true,
            });

            await web3.eth
                .sendTransaction({
                    from: deployer,
                    value: price.toString(),
                    to: LandNFTAddress,
                    gas: 5000000,
                    data: LandNFT.methods
                        .mint(deployer, parseInt(id))
                        .encodeABI(),
                })
                .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
                .on('receipt', async receipt => {
                    props.setLoader({ status: false });
                    EventBus.publish("success", `Land Purchased Successfully!`);
                    setId(0);
                    setShow(false);
                });
        } catch (e) {
            console.log(e);
            props.setLoader({
                message: "Purchase Not Completed...",
                status: false,
            });
            EventBus.publish("error", `Purchase Failed`);
        }
    };


    const [overlayText, setOverlayText] = useState('');
    const [overlayStyle, setOverlayStyle] = useState({ display: 'none' });

    useEffect(() => {
        const areas = document.querySelectorAll('map area');

        function handleMouseOver(event) {
            const text = event.target.dataset.text;
            setOverlayText(text);
            setOverlayStyle({
                display: 'block',
                top: `${event.clientY}px`,
                left: `${event.clientX}px`
            });
        }

        function handleMouseOut() {
            setOverlayStyle({ display: 'none' });
        }

        areas.forEach(area => {
            area.addEventListener('mouseover', handleMouseOver);
            area.addEventListener('mouseout', handleMouseOut);
        });

        return () => {
            areas.forEach(area => {
                area.removeEventListener('mouseover', handleMouseOver);
                area.removeEventListener('mouseout', handleMouseOut);
            });
        };
    }, []);

    return (
        <>
            <div className="account sidebar">
                <div className={isActive ? 'left open' : 'left'}>
                    <div className='inner'>
                        <Link className="logo" to="/">
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>

                    <MainLinks />
                </div>

                <div className={isActive ? 'bg-open active' : 'bg-open'}>
                </div>

                <button className={isActive ? 'sidebar-arrow active' : 'sidebar-arrow'} onClick={sidebarArrow}>
                    <img src={sidebararrow} />
                </button>

                <div className="right">
                    <TopBar />

                    <div className='container'>
                        <div className="image-mapping">
                            <div className='img-wrapper'>
                                <div style={{ position: 'relative' }}>
                                    <img src="images/map.png" alt="Workplace" usemap="#workmap" width="400" height="379" />
                                    <div id="overlay" style={overlayStyle}>{overlayText}</div>
                                </div>

                                <map name="workmap">
                                    {/* Market Store */}

                                    <area onClick={handleShow} shape="rect" coords="839,1023,896,1071" alt="Market Store 1" title="Market Store 1" href="images/store/store_large_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="904,1023,958,1071" alt="Market Store 2" title="Market Store 2" href="images/store/store_large_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="839,1201,896,1249" alt="Market Store 3" title="Market Store 3" href="images/store/store_large_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="904,1201,958,1249" alt="Market Store 4" title="Market Store 4" href="images/store/store_large_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="839,1258,896,1305" alt="Market Store 5" title="Market Store 5" href="images/store/store_large_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="904,1258,958,1305" alt="Market Store 6" title="Market Store 6" href="images/store/store_large_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="839,1435,896,1484" alt="Market Store 7" title="Market Store 7" href="images/store/store_large_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="904,1435,958,1484" alt="Market Store 8" title="Market Store 8" href="images/store/store_large_rec.png" />

                                    <area onClick={handleShow} shape="rect" coords="788,1095,835,1131" alt="Market Store 9" href="images/store/store_med_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="788,1140,835,1179" alt="Market Store 10" href="images/store/store_med_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="788,1328,835,1364" alt="Market Store 11" href="images/store/store_med_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="788,1372,835,1411" alt="Market Store 12" href="images/store/store_med_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="965,1095,1012,1131" alt="Market Store 13" href="images/store/store_med_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="965,1140,1012,1179" alt="Market Store 14" href="images/store/store_med_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="965,1328,1012,1364" alt="Market Store 15" href="images/store/store_med_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="965,1372,1012,1411" alt="Market Store 16" href="images/store/store_med_rec.png" />

                                    {/* Offices */}

                                    <area onClick={handleShow} shape="rect" coords="834,1725,871,1746" alt="Office 1" title="Office 1" href="images/offices_and_auditorium/offices_sides_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="834,1773,871,1795" alt="Office 2" title="Office 2" href="images/offices_and_auditorium/offices_sides_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="834,1820,871,1842" alt="Office 3" title="Office 3" href="images/offices_and_auditorium/offices_sides_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="834,1868,871,1890" alt="Office 4" title="Office 4" href="images/offices_and_auditorium/offices_sides_rec.png" />

                                    <area onClick={handleShow} shape="rect" coords="1104,1725,1140,1746" alt="Office 5" title="Office 5" href="images/offices_and_auditorium/offices_sides_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="1104,1773,1140,1795" alt="Office 6" title="Office 6" href="images/offices_and_auditorium/offices_sides_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="1104,1820,1140,1842" alt="Office 7" title="Office 7" href="images/offices_and_auditorium/offices_sides_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="1104,1868,1140,1890" alt="Office 8" title="Office 8" href="images/offices_and_auditorium/offices_sides_rec.png" />

                                    <area onClick={handleShow} shape="rect" coords="895,1725,927,1746" alt="Office 9" title="Office 9" href="images/offices_and_auditorium/offices_xentre_rec_1.png" />
                                    <area onClick={handleShow} shape="rect" coords="934,1725,966,1746" alt="Office 10" title="Office 10" href="images/offices_and_auditorium/offices_xentre_rec_1.png" />
                                    <area onClick={handleShow} shape="rect" coords="1007,1725,1040,1746" alt="Office 11" title="Office 11" href="images/offices_and_auditorium/offices_xentre_rec_1.png" />
                                    <area onClick={handleShow} shape="rect" coords="1045,1725,1078,1746" alt="Office 12" title="Office 12" href="images/offices_and_auditorium/offices_xentre_rec_1.png" />

                                    <area onClick={handleShow} shape="rect" coords="895,1771,927,1796" alt="Office 13" title="Office 13" href="images/offices_and_auditorium/offices_xentre_rec_1.png" />
                                    <area onClick={handleShow} shape="poly" coords="934,1771,967,1771,967,1781,975,1781,975,1796,934,1796" alt="Office 14" title="Office 14" href="images/offices_and_auditorium/offices_xentre_rec_2.png" />

                                    <area onClick={handleShow} shape="rect" coords="1045,1771,1078,1796" alt="Office 15" title="Office 15" href="images/offices_and_auditorium/offices_xentre_rec_1.png" />
                                    <area onClick={handleShow} shape="poly" coords="998,1781,1005,1781,1007,1771,1039,1771,1040,1796,998,1796" alt="Office 16" title="Office 16" href="images/offices_and_auditorium/offices_xentre_rec_2.png" />

                                    <area onClick={handleShow} shape="rect" coords="895,1820,974,1869" alt="Office 17" title="Office 17" href="images/offices_and_auditorium/offices_large_rec_(auditorium).png" />
                                    <area onClick={handleShow} shape="rect" coords="999,1820,1076,1869" alt="Office 18" title="Office 18" href="images/offices_and_auditorium/offices_large_rec_(auditorium).png" />
                                    <area onClick={handleShow} shape="rect" coords="895,1898,974,1947" alt="Office 19" title="Office 19" href="images/offices_and_auditorium/offices_large_rec_(auditorium).png" />
                                    <area onClick={handleShow} shape="rect" coords="1004,1899,1079,947" alt="Office 20" title="Office 20" href="images/offices_and_auditorium/offices_large_rec_(auditorium).png" />

                                    {/* Clinics */}

                                    <area onClick={handleShow} shape="rect" coords="1407,1180,1464,1239" alt="Clinic 1" title="Clinic 1" href="images/clinics/clinics_large_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="1470,1180,1528,1239" alt="Clinic 2" title="Clinic 2" href="images/clinics/clinics_large_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="1535,1180,1580,1239" alt="Clinic 3" title="Clinic 3" href="images/clinics/clinics_midium_rec.png" />

                                    <area onClick={handleShow} shape="rect" coords="1688,1180,1733,1239" alt="Clinic 4" title="Clinic 4" href="images/clinics/clinics_midium_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="1739,1180,1798,1239" alt="Clinic 5" title="Clinic 5" href="images/clinics/clinics_large_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="1805,1180,1861,1239" alt="Clinic 6" title="Clinic 6" href="images/clinics/clinics_large_rec.png" />

                                    <area onClick={handleShow} shape="rect" coords="1407,1276,1464,1335" alt="Clinic 7" title="Clinic 7" href="images/clinics/offices_clinics_large_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="1470,1276,1528,1335" alt="Clinic 8" title="Clinic 8" href="images/clinics/offices_clinics_large_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="1535,1276,1580,1335" alt="Clinic 9" title="Clinic 9" href="images/clinics/clinics_midium_rec.png" />

                                    <area onClick={handleShow} shape="rect" coords="1688,1276,1733,1335" alt="Clinic 10" title="Clinic 10" href="images/clinics/clinics_midium_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="1739,1276,1798,1335" alt="Clinic 11" title="Clinic 11" href="images/clinics/offices_clinics_large_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="1805,1276,1861,1335" alt="Clinic 12" title="Clinic 12" href="images/clinics/offices_clinics_large_rec.png" />

                                    <area onClick={handleShow} shape="rect" coords="1367,1378,1412,1423" alt="Clinic 13" title="Clinic 13" href="images/clinics/clinics_midium_rec_2.png" />
                                    <area onClick={handleShow} shape="rect" coords="1419,1378,1465,1423" alt="Clinic 14" title="Clinic 14" href="images/clinics/clinics_midium_rec_2.png" />
                                    <area onClick={handleShow} shape="rect" coords="1471,1378,1493,1423" alt="Clinic 15" title="Clinic 15" href="images/clinics/clinics_small_rec.png" />

                                    <area onClick={handleShow} shape="rect" coords="1778,1378,1799,1423" alt="Clinic 16" title="Clinic 16" href="images/clinics/clinics_small_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="1804,1378,1849,1423" alt="Clinic 17" title="Clinic 17" href="images/clinics/clinics_midium_rec_2.png" />
                                    <area onClick={handleShow} shape="rect" coords="1855,1378,1901,1423" alt="Clinic 18" title="Clinic 18" href="images/clinics/clinics_midium_rec_2.png" />

                                    <area onClick={handleShow} shape="rect" coords="1498,1421,1555,1478" alt="Clinic 19" title="Clinic 19" href="images/clinics/clinics_large_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="1563,1421,1620,1478" alt="Clinic 20" title="Clinic 20" href="images/clinics/clinics_large_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="1651,1421,1708,1478" alt="Clinic 21" title="Clinic 21" href="images/clinics/clinics_large_rec.png" />
                                    <area onClick={handleShow} shape="rect" coords="1714,1421,1771,1478" alt="Clinic 22" title="Clinic 22" href="images/clinics/clinics_large_rec.png" />

                                    {/* Blocks */}

                                    {/* Block 1 */}
                                    {/* <area onClick={window.location.href = `minting/${1}`} id="1" alt="b_1_1" title="b_1_1" href="#" coords="1488,524,1566,602" shape="rect"></area> */}
                                    <area onClick={() => handleShow(1)} id="1" alt="b_1_1" title="b_1_1" href="#" coords="1488,524,1566,602" shape="rect"></area>
                                    <area onClick={() => handleShow(2)} id="2" alt="b_1_2" title="b_1_2" href="#" coords="1587,524,1671,602" shape="rect"></area>
                                    <area onClick={() => handleShow(3)} id="3" alt="b_1_3" title="b_1_3" href="#" coords="1682,524,1765,602" shape="rect"></area>

                                    <area onClick={() => handleShow(4)} id="4" alt="b_1_4" title="b_1_3" href="#" coords="1490,638,1566,713" shape="rect"></area>
                                    <area onClick={() => handleShow(5)} id="5" alt="b_1_5" title="b_1_5" href="#" coords="1589,638,1664,713" shape="rect"></area>
                                    <area onClick={() => handleShow(6)} id="6" alt="b_1_6" title="b_1_6" href="#" coords="1687,638,1763,713" shape="rect"></area>

                                    <area onClick={() => handleShow(7)} id="7" alt="b_1_7" title="b_1_7" href="#" coords="1490,742,1560,818" shape="rect"></area>
                                    <area onClick={() => handleShow(8)} id="8" alt="b_1_8" title="b_1_8" href="#" coords="1589,742,1664,818" shape="rect"></area>
                                    <area onClick={() => handleShow(9)} id="9" alt="b_1_9" title="b_1_9" href="#" coords="1682,742,1757,818" shape="rect"></area>

                                    <area onClick={() => handleShow(10)} id="10" alt="b_1_10" title="b_1_10" href="#" coords="1490,847,1560,922" shape="rect"></area>
                                    <area onClick={() => handleShow(11)} id="11" alt="b_1_11" title="b_1_11" href="#" coords="1589,847,1664,922" shape="rect"></area>
                                    <area onClick={() => handleShow(12)} id="12" alt="b_1_12" title="b_1_12" href="#" coords="1682,847,1757,922" shape="rect"></area>

                                    {/* Block2 */}
                                    <area onClick={() => handleShow(13)} id="13" alt="b_2_1" title="b_2_1" href="#" coords="1960,533,2035,609" shape="rect"></area>
                                    <area onClick={() => handleShow(14)} id="14" alt="b_2_2" title="b_2_2" href="#" coords="2058,533,2134,609" shape="rect"></area>
                                    <area onClick={() => handleShow(15)} id="15" alt="b_2_3" title="b_2_3" href="#" coords="2151,533,2232,609" shape="rect"></area>
                                    <area onClick={() => handleShow(16)} id="16" alt="b_2_4" title="b_2_4" href="#" coords="2244,533,2325,609" shape="rect"></area>
                                    <area onClick={() => handleShow(17)} id="17" alt="b_2_5" title="b_2_5" href="#" coords="2337,533,2418,609" shape="rect"></area>
                                    <area onClick={() => handleShow(18)} id="18" alt="b_2_6" title="b_2_6" href="#" coords="2430,533,2511,609" shape="rect"></area>

                                    <area onClick={() => handleShow(19)} id="19" alt="b_2_7" title="b_2_7" href="#" coords="1960,644,2035,719" shape="rect"></area>
                                    <area onClick={() => handleShow(20)} id="20" alt="b_2_8" title="b_2_8" href="#" coords="2058,644,2134,719" shape="rect"></area>
                                    <area onClick={() => handleShow(21)} id="21" alt="b_2_9" title="b_2_9" href="#" coords="2151,644,2232,719" shape="rect"></area>
                                    <area onClick={() => handleShow(22)} id="22" alt="b_2_10" title="b_2_10" href="#" coords="2244,644,2325,719" shape="rect"></area>
                                    <area onClick={() => handleShow(23)} id="23" alt="b_2_11" title="b_2_11" href="#" coords="2337,644,2418,713" shape="rect"></area>
                                    <area onClick={() => handleShow(24)} id="24" alt="b_2_12" title="b_2_12" href="#" coords="2430,644,2511,713" shape="rect"></area>

                                    <area onClick={() => handleShow(25)} id="25" alt="b_2_13" title="b_2_13" href="#" coords="1960,742,2035,818" shape="rect"></area>
                                    <area onClick={() => handleShow(26)} id="26" alt="b_2_14" title="b_2_14" href="#" coords="2058,742,2134,818" shape="rect"></area>
                                    <area onClick={() => handleShow(27)} id="27" alt="b_2_15" title="b_2_15" href="#" coords="2151,742,2232,818" shape="rect"></area>
                                    <area onClick={() => handleShow(28)} id="28" alt="b_2_16" title="b_2_16" href="#" coords="2244,742,2325,823" shape="rect"></area>
                                    <area onClick={() => handleShow(29)} id="29" alt="b_2_17" title="b_2_17" href="#" coords="2337,742,2418,818" shape="rect"></area>
                                    <area onClick={() => handleShow(30)} id="30" alt="b_2_18" title="b_2_18" href="#" coords="2430,742,2511,818" shape="rect"></area>

                                    <area onClick={() => handleShow(31)} id="31" alt="b_2_19" title="b_2_19" href="#" coords="1960,841,2035,922" shape="rect"></area>
                                    <area onClick={() => handleShow(32)} id="32" alt="b_2_20" title="b_2_20" href="#" coords="2058,841,2134,922" shape="rect"></area>
                                    <area onClick={() => handleShow(33)} id="33" alt="b_2_21" title="b_2_21" href="#" coords="2151,841,2232,922" shape="rect"></area>
                                    <area onClick={() => handleShow(34)} id="34" alt="b_2_22" title="b_2_22" href="#" coords="2244,841,2325,922" shape="rect"></area>
                                    <area onClick={() => handleShow(35)} id="35" alt="b_2_23" title="b_2_23" href="#" coords="2337,841,2418,922" shape="rect"></area>
                                    <area onClick={() => handleShow(36)} id="36" alt="b_2_24" title="b_2_24" href="#" coords="2430,841,2511,922" shape="rect"></area>
                                </map>

                                <Modal className="minting-engine text-center" show={show} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <h2>Minting</h2>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <p>"Seize, Discover, and Transform Your Virtual Space to Become a Royalty in the Xverse"</p>
                                        <div className="info-wrap">
                                            <div className="info">
                                                <p>Plot ID</p>
                                                <p>{id}</p>
                                            </div>

                                            <div className="info">
                                                <p>Price of Plot</p>
                                                <p>{price} XDC</p>
                                            </div>

                                            <div className="info">
                                                <p>Connected address</p>
                                                <p>{props.publicAddress && props.publicAddress.substring(0, 5) + '...' + props.publicAddress.substring(37, props.publicAddress.length)}</p>
                                            </div>
                                        </div>
                                        <button className="common-btn" onClick={() => mintToken(id)}>Mint Now</button>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapDispatchToProps = {
    setLoader
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, xdcPrice } = Auth;
    return { publicAddress, xdcPrice }
};

export default connect(mapStateToProps, mapDispatchToProps)(Mapping);