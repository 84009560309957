import React from 'react';
import EventBus from 'eventing-bus';
import { connect } from "react-redux";
import { Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";

import { web3 } from "../store/web3";
import { networkId } from '../store/config';
import { logout, setAddress, setNetwork, getNonce, login } from '../store/actions/Auth';

const profileimg = "/images/profile-img.png";
const logo = "/images/main-logo.png";

const TopBar = (props) => {

    async function connectWallet(networkId) {
        if (typeof window.ethereum === 'undefined') {
            EventBus.publish('error', `Please Install Metamask!!!`);
            return;
        }

        web3.eth.net.getId(async (err, netId) => {
            if (netId !== networkId) {
                if (networkId == 51) EventBus.publish('info', "please select XDC Testnet");
                if (networkId == 50) EventBus.publish('info', "please select XDC Mainnet");
                return;
            }
            let x = await web3.currentProvider.chainId;
            if (!x) {
                EventBus.publish('error', `Please Install Metamask!!!`);
                return;
            }
            let address = (await web3.currentProvider.enable())[0];
            if (address) props.setAddress({publicAddress:address,chain: networkId});
        });
    };

    return (
        <>
            <header className="topbar">
                <div className="container">
                    <nav className="navbar navbar-expand-xl">
                        <Link className="logo" to="/">
                            <img src={logo} alt="logo" />
                        </Link>

                        <Form>
                            {/* <Form.Label>Soul bound token ID</Form.Label>
                        <Form.Control type="text" placeholder="wallet address" /> */}
                            {props.publicAddress === '' || props.publicAddress === null ?
                                <Button onClick={() => connectWallet(networkId)}> Connect Metamask </Button> :
                                <Button onClick={() => connectWallet(networkId)}> {props.publicAddress && props.publicAddress.substring(0, 5) + '...' + props.publicAddress.substring(37, props.publicAddress.length)} </Button>
                            }
                        </Form>

                        <a class="profile" href="">
                            <span>Michael D</span>

                            <div className='img-wrap'>
                                <img src={profileimg} alt="" />
                            </div>
                        </a>

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-bar"></span>
                            <span className="navbar-toggler-bar"></span>
                            <span className="navbar-toggler-bar"></span>
                        </button>
                    </nav>
                </div>
            </header>
        </>
    );
};

const mapDispatchToProps = { logout, setAddress, setNetwork, getNonce, login };

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, network, nonce, isLogin, isNonce, isChangeNetwork } = Auth;
    return { publicAddress, network, nonce, isLogin, isNonce, isChangeNetwork }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);