import React, { useState, useEffect } from 'react';
import { NavLink, Outlet, useLocation } from "react-router-dom";

const dashboardicon = "/images/dashboard-icon.png";
const nftmintingicon = "/images/nft-minting-icon.png";
const daoicon = "/images/dao-icon.png";
const stakeicon = "/images/stake-icon.png";
const mapicon = "/images/map-icon.png";

const MainLinks = (props) => {

    const location = useLocation();

    return (
        <>
            <ul className="navbar-nav">
                <li className="nav-item">
                    <NavLink className={location.pathname === '/dashboard' ? 'active' : ''} to="/dashboard"><img src={dashboardicon} alt="logo" />Dashboard</NavLink>
                </li>

                <li className="nav-item">
                    <NavLink className={location.pathname === '/nftminting' ? 'active' : ''} to="/nftminting"><img src={nftmintingicon} alt="logo" />NFT Minting</NavLink>
                </li>

                <li className="nav-item">
                    <NavLink className={location.pathname === '/stake' ? 'active' : ''} to="/stake"><img src={stakeicon} alt="logo" />Stake</NavLink>
                </li>

                <li className="nav-item">
                    <NavLink className={location.pathname === '/dao' ? 'active' : ''} to="/dao"><img src={daoicon} alt="logo" />DAO</NavLink>
                </li>

                <li className="nav-item">
                    <NavLink className={location.pathname === '/mapping' ? 'active' : ''} to="/mapping"><img src={mapicon} alt="logo" />Map</NavLink>
                </li>

                {/* <li className="nav-item">
                    <NavLink className={location.pathname === '/minting' ? 'active' : ''} to="/minting"><img src={nftmintingicon} alt="logo" />Minting</NavLink>
                </li> */}
            </ul>
        </>
    )
}

export default MainLinks;

