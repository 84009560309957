/* -- set app title --*/
const AppTitle = 'Marketplace';

/* -- set app mode -- */
// const AppMode = [''];
const AppMode = ['development'];

/* -- set API URLs --*/
const testing = 'http://localhost:4000';
const production = 'http://localhost:4000';
// const development = 'http://localhost:4000';
const development = 'https://dserver.xvrse.ai';

let SocketUrl;
let env = AppMode[0] || 'development', networkType = '', networkId = 51;

switch (AppMode[0]) {
  case 'development':
    networkId = 51;
    SocketUrl = development;
    networkType = true;
    break;
  case 'production':
    networkId = 50;
    SocketUrl = production;
    networkType = false;
    break;
  case 'testing':
    networkId = 51;
    SocketUrl = development;
    networkType = true;
    break;
  default:
    networkId = 51;
    SocketUrl = development;
    networkType = true;
}
let ApiUrl = `${SocketUrl}/api/v1`;
export { AppTitle, ApiUrl, SocketUrl, networkType, env, networkId };