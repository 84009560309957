import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { React, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Modal } from 'react-bootstrap';

import Header from '../TopBar';
import MainLinks from '../MainLinks';
import { web3 } from "../../store/web3";
import { setLoader } from "../../store/actions/Auth";
import { makeTokens } from "../../store/contract/index";

const logo = "/images/main-logo.png";
const collectionimg1 = "/images/collectionimg1.png";
const collectionimg2 = "/images/collectionimg2.png";
const collectionimg3 = "/images/collectionimg3.png";
const sidebararrow = "/images/sidebar-arrow.png";

const Stake = (props) => {

    const onOpen = () => SetOPen(true);
    const [Open, SetOPen] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const sidebarArrow = () => {
        setIsActive(!isActive);
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const navigate = useNavigate();

    // =================================  GOVERNOR STAKES  ================================= //

    async function stakeGovernor() {
        try {

            const waitFor = (delay) =>
                new Promise((resolve) => setTimeout(resolve, delay));

            let { publicAddress } = props;

            if (publicAddress == null || publicAddress == undefined) {
                EventBus.publish("error", `Please connect your wallet!`);
                return;
            }

            const { GovernorNFT, GovernorStakeAddress, GovernorStake } = await makeTokens();

            let deployer = (await web3.currentProvider.enable())[0];

            let holdings = await GovernorNFT.methods.balanceOf(deployer, 1).call();

            if (holdings == 0) {
                EventBus.publish("error", `You don't own NFT from this collection!`);
                return;
            }

            const balanceWei = await web3.eth.getBalance(deployer);
            const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

            props.setLoader({
                message: "Approval in Progress...",
                status: true,
            });

            await GovernorNFT.methods.setApprovalForAll(GovernorStakeAddress, true).send({ from: deployer });

            props.setLoader({ status: false });

            props.setLoader({
                message: "Stake in Progress...",
                status: true,
            });

            await web3.eth
                .sendTransaction({
                    from: deployer,
                    value: 0,
                    to: GovernorStakeAddress,
                    gas: 5000000,
                    data: GovernorStake.methods
                        .stake(1)
                        .encodeABI(),
                })
                .on("transactionHash", hash => console.log(`************** deploy contract hash = ${hash}`))
                .on('receipt', async receipt => {
                    props.setLoader({ status: false });
                    EventBus.publish("success", `NFT Staked Successfully!`);
                });
        } catch (e) {
            console.log(e);
            props.setLoader({
                message: "Stake Not Completed...",
                status: false,
            });
            EventBus.publish("error", `Staking Failed`);
        }
    };

    async function unStakeGovernor() {
        try {

            const waitFor = (delay) =>
                new Promise((resolve) => setTimeout(resolve, delay));

            let { publicAddress } = props;

            if (publicAddress == null || publicAddress == undefined) {
                EventBus.publish("error", `Please connect your wallet!`);
                return;
            }

            const { GovernorStakeAddress, GovernorStake } = await makeTokens();

            let deployer = (await web3.currentProvider.enable())[0];

            let currentStakes = await GovernorStake.methods.totalStake(deployer).call({ from: deployer });

            if (currentStakes['0'] == 0) {
                EventBus.publish("error", `You have no NFTs staked in this collection yet!!`);
                return;
            }

            const balanceWei = await web3.eth.getBalance(deployer);
            const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

            props.setLoader({
                message: "Un-staking in Progress...",
                status: true,
            });

            await web3.eth
                .sendTransaction({
                    from: deployer,
                    value: 0,
                    to: GovernorStakeAddress,
                    gas: 5000000,
                    data: GovernorStake.methods
                        .withdraw(1)
                        .encodeABI(),
                })
                .on("transactionHash", hash => console.log(`************** deploy contract hash = ${hash}`))
                .on('receipt', async receipt => {
                    props.setLoader({ status: false });
                    EventBus.publish("success", `Un-staked Successfully!`);
                });
        } catch (e) {
            console.log(e);
            props.setLoader({
                message: "Un-stake Not Completed...",
                status: false,
            });
            EventBus.publish("error", `Un-staking Failed`);
        }
    };

    async function claimGovernor() {
        try {

            const waitFor = (delay) =>
                new Promise((resolve) => setTimeout(resolve, delay));

            let { publicAddress } = props;

            if (publicAddress == null || publicAddress == undefined) {
                EventBus.publish("error", `Please connect your wallet!`);
                return;
            }

            const { GovernorStakeAddress, GovernorStake } = await makeTokens();

            let deployer = (await web3.currentProvider.enable())[0];

            const balanceWei = await web3.eth.getBalance(deployer);
            const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

            let currentStakes = await GovernorStake.methods.totalStake(deployer).call({ from: deployer });

            if (currentStakes['0'] == 0) {
                EventBus.publish("error", `You have no NFTs staked in this collection yet!!`);
                return;
            }

            props.setLoader({
                message: "Claim in Progress...",
                status: true,
            });

            await web3.eth
                .sendTransaction({
                    from: deployer,
                    value: 0,
                    to: GovernorStakeAddress,
                    gas: 5000000,
                    data: GovernorStake.methods
                        .claimReward()
                        .encodeABI(),
                })
                .on("transactionHash", hash => console.log(`************** deploy contract hash = ${hash}`))
                .on('receipt', async receipt => {
                    props.setLoader({ status: false });
                    EventBus.publish("success", `Claimed Successfully!`);
                });
        } catch (e) {
            console.log(e);
            props.setLoader({
                message: "Un-stake Not Completed...",
                status: false,
            });
            EventBus.publish("error", `Claim Failed`);
        }
    };

    // =================================  Business STAKES  ================================= //

    async function stakeBusiness() {
        try {

            const waitFor = (delay) =>
                new Promise((resolve) => setTimeout(resolve, delay));

            let { publicAddress } = props;

            if (publicAddress == null || publicAddress == undefined) {
                EventBus.publish("error", `Please connect your wallet!`);
                return;
            }

            const { BusinessNFT, BusinessStakeAddress, BusinessStake } = await makeTokens();

            let deployer = (await web3.currentProvider.enable())[0];

            let holdings = await BusinessNFT.methods.balanceOf(deployer, 1).call();

            if (holdings == 0) {
                EventBus.publish("error", `You don't own NFT from this collection!`);
                return;
            }

            const balanceWei = await web3.eth.getBalance(deployer);
            const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

            props.setLoader({
                message: "Approval in Progress...",
                status: true,
            });

            await BusinessNFT.methods.setApprovalForAll(BusinessStakeAddress, true).send({ from: deployer });

            props.setLoader({ status: false });

            props.setLoader({
                message: "Stake in Progress...",
                status: true,
            });

            await web3.eth
                .sendTransaction({
                    from: deployer,
                    value: 0,
                    to: BusinessStakeAddress,
                    gas: 5000000,
                    data: BusinessStake.methods
                        .stake(1)
                        .encodeABI(),
                })
                .on("transactionHash", hash => console.log(`************** deploy contract hash = ${hash}`))
                .on('receipt', async receipt => {
                    props.setLoader({ status: false });
                    EventBus.publish("success", `NFT Staked Successfully!`);
                });
        } catch (e) {
            console.log(e);
            props.setLoader({
                message: "Stake Not Completed...",
                status: false,
            });
            EventBus.publish("error", `Staking Failed`);
        }
    };

    async function unStakeBusiness() {
        try {

            const waitFor = (delay) =>
                new Promise((resolve) => setTimeout(resolve, delay));

            let { publicAddress } = props;

            if (publicAddress == null || publicAddress == undefined) {
                EventBus.publish("error", `Please connect your wallet!`);
                return;
            }

            const { BusinessStakeAddress, BusinessStake } = await makeTokens();

            let deployer = (await web3.currentProvider.enable())[0];

            let currentStakes = await BusinessStake.methods.totalStake(deployer).call({ from: deployer });

            if (currentStakes['0'] == 0) {
                EventBus.publish("error", `You have no NFTs staked in this collection yet!!`);
                return;
            }

            const balanceWei = await web3.eth.getBalance(deployer);
            const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

            props.setLoader({
                message: "Un-staking in Progress...",
                status: true,
            });

            await web3.eth
                .sendTransaction({
                    from: deployer,
                    value: 0,
                    to: BusinessStakeAddress,
                    gas: 5000000,
                    data: BusinessStake.methods
                        .withdraw(1)
                        .encodeABI(),
                })
                .on("transactionHash", hash => console.log(`************** deploy contract hash = ${hash}`))
                .on('receipt', async receipt => {
                    props.setLoader({ status: false });
                    EventBus.publish("success", `Un-staked Successfully!`);
                });
        } catch (e) {
            console.log(e);
            props.setLoader({
                message: "Un-stake Not Completed...",
                status: false,
            });
            EventBus.publish("error", `Un-staking Failed`);
        }
    };

    async function claimBusiness() {
        try {

            const waitFor = (delay) =>
                new Promise((resolve) => setTimeout(resolve, delay));

            let { publicAddress } = props;

            if (publicAddress == null || publicAddress == undefined) {
                EventBus.publish("error", `Please connect your wallet!`);
                return;
            }

            const { BusinessStakeAddress, BusinessStake } = await makeTokens();

            let deployer = (await web3.currentProvider.enable())[0];

            const balanceWei = await web3.eth.getBalance(deployer);
            const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

            let currentStakes = await BusinessStake.methods.totalStake(deployer).call({ from: deployer });

            if (currentStakes['0'] == 0) {
                EventBus.publish("error", `You have no NFTs staked in this collection yet!!`);
                return;
            }

            props.setLoader({
                message: "Claim in Progress...",
                status: true,
            });

            await web3.eth
                .sendTransaction({
                    from: deployer,
                    value: 0,
                    to: BusinessStakeAddress,
                    gas: 5000000,
                    data: BusinessStake.methods
                        .claimReward()
                        .encodeABI(),
                })
                .on("transactionHash", hash => console.log(`************** deploy contract hash = ${hash}`))
                .on('receipt', async receipt => {
                    props.setLoader({ status: false });
                    EventBus.publish("success", `Claimed Successfully!`);
                });
        } catch (e) {
            console.log(e);
            props.setLoader({
                message: "Un-stake Not Completed...",
                status: false,
            });
            EventBus.publish("error", `Claim Failed`);
        }
    };

    // =================================  Citizen STAKES  ================================= //

    async function stakeCitizen() {
        try {

            const waitFor = (delay) =>
                new Promise((resolve) => setTimeout(resolve, delay));

            let { publicAddress } = props;

            if (publicAddress == null || publicAddress == undefined) {
                EventBus.publish("error", `Please connect your wallet!`);
                return;
            }

            const { CitizenNFT, CitizenStakeAddress, CitizenStake } = await makeTokens();

            let deployer = (await web3.currentProvider.enable())[0];

            let holdings = await CitizenNFT.methods.balanceOf(deployer, 1).call();

            if (holdings == 0) {
                EventBus.publish("error", `You don't own NFT from this collection!`);
                return;
            }

            const balanceWei = await web3.eth.getBalance(deployer);
            const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

            props.setLoader({
                message: "Approval in Progress...",
                status: true,
            });

            await CitizenNFT.methods.setApprovalForAll(CitizenStakeAddress, true).send({ from: deployer });

            props.setLoader({ status: false });

            props.setLoader({
                message: "Stake in Progress...",
                status: true,
            });

            await web3.eth
                .sendTransaction({
                    from: deployer,
                    value: 0,
                    to: CitizenStakeAddress,
                    gas: 5000000,
                    data: CitizenStake.methods
                        .stake(1)
                        .encodeABI(),
                })
                .on("transactionHash", hash => console.log(`************** deploy contract hash = ${hash}`))
                .on('receipt', async receipt => {
                    props.setLoader({ status: false });
                    EventBus.publish("success", `NFT Staked Successfully!`);
                });
        } catch (e) {
            console.log(e);
            props.setLoader({
                message: "Stake Not Completed...",
                status: false,
            });
            EventBus.publish("error", `Staking Failed`);
        }
    };

    async function unStakeCitizen() {
        try {

            const waitFor = (delay) =>
                new Promise((resolve) => setTimeout(resolve, delay));

            let { publicAddress } = props;

            if (publicAddress == null || publicAddress == undefined) {
                EventBus.publish("error", `Please connect your wallet!`);
                return;
            }

            const { CitizenStakeAddress, CitizenStake } = await makeTokens();

            let deployer = (await web3.currentProvider.enable())[0];

            let currentStakes = await CitizenStake.methods.totalStake(deployer).call({ from: deployer });

            if (currentStakes['0'] == 0) {
                EventBus.publish("error", `You have no NFTs staked in this collection yet!!`);
                return;
            }

            const balanceWei = await web3.eth.getBalance(deployer);
            const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

            props.setLoader({
                message: "Un-staking in Progress...",
                status: true,
            });

            await web3.eth
                .sendTransaction({
                    from: deployer,
                    value: 0,
                    to: CitizenStakeAddress,
                    gas: 5000000,
                    data: CitizenStake.methods
                        .withdraw(1)
                        .encodeABI(),
                })
                .on("transactionHash", hash => console.log(`************** deploy contract hash = ${hash}`))
                .on('receipt', async receipt => {
                    props.setLoader({ status: false });
                    EventBus.publish("success", `Un-staked Successfully!`);
                });
        } catch (e) {
            console.log(e);
            props.setLoader({
                message: "Un-stake Not Completed...",
                status: false,
            });
            EventBus.publish("error", `Un-staking Failed`);
        }
    };

    async function claimCitizen() {
        try {

            const waitFor = (delay) =>
                new Promise((resolve) => setTimeout(resolve, delay));

            let { publicAddress } = props;

            if (publicAddress == null || publicAddress == undefined) {
                EventBus.publish("error", `Please connect your wallet!`);
                return;
            }

            const { CitizenStakeAddress, CitizenStake } = await makeTokens();

            let deployer = (await web3.currentProvider.enable())[0];

            const balanceWei = await web3.eth.getBalance(deployer);
            const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

            let currentStakes = await CitizenStake.methods.totalStake(deployer).call({ from: deployer });

            if (currentStakes['0'] == 0) {
                EventBus.publish("error", `You have no NFTs staked in this collection yet!!`);
                return;
            }

            props.setLoader({
                message: "Claim in Progress...",
                status: true,
            });

            await web3.eth
                .sendTransaction({
                    from: deployer,
                    value: 0,
                    to: CitizenStakeAddress,
                    gas: 5000000,
                    data: CitizenStake.methods
                        .claimReward()
                        .encodeABI(),
                })
                .on("transactionHash", hash => console.log(`************** deploy contract hash = ${hash}`))
                .on('receipt', async receipt => {
                    props.setLoader({ status: false });
                    EventBus.publish("success", `Claimed Successfully!`);
                });
        } catch (e) {
            console.log(e);
            props.setLoader({
                message: "Un-stake Not Completed...",
                status: false,
            });
            EventBus.publish("error", `Claim Failed`);
        }
    };

    return (
        <>
            <div className="account sidebar">
                <div className={isActive ? 'left open' : 'left'}>
                    <div className='inner'>
                        <Link className="logo" to="/">
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>

                    <MainLinks />
                </div>

                <div className={isActive ? 'bg-open active' : 'bg-open'}>
                </div>

                <button className={isActive ? 'sidebar-arrow active' : 'sidebar-arrow'} onClick={sidebarArrow}>
                    <img src={sidebararrow} />
                </button>

                <div className="right">
                    <Header />

                    <div className="collection-wrap">
                        <div className="container">
                            <div className="row">
                                <div className='col-xl-4 col-md-6'>
                                    <div className='colletion-box'>
                                        <img src={collectionimg1} />

                                        <div className="content">
                                            <div className="content-head">
                                                {/* <img src={profileimg} /> */}

                                                <div className="">
                                                    <h3>Governor NFT</h3>
                                                    <p>GVN NFT</p>
                                                </div>
                                            </div>

                                            <div className='btns-wrapper'>
                                                <div className="stake-unstake">
                                                    <button className="active" onClick={() => stakeGovernor()}>Stake</button>
                                                    <button onClick={() => unStakeGovernor()}>Unstake</button>
                                                </div>

                                                <button onClick={() => claimGovernor()}>Claim</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-xl-4 col-md-6'>
                                    <div className='colletion-box'>
                                        <img src={collectionimg2} />

                                        <div className="content">
                                            <div className="content-head">
                                                {/* <img src={profileimg} /> */}

                                                <div className="">
                                                    <h3>Business NFT</h3>
                                                    <p>Business NFT</p>
                                                </div>
                                            </div>

                                            <div className='btns-wrapper'>
                                                <div className="stake-unstake">
                                                    <button className="active" onClick={() => stakeBusiness()}>Stake</button>
                                                    <button onClick={() => unStakeBusiness()}>Unstake</button>
                                                </div>

                                                <button onClick={() => claimBusiness()}>Claim</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-xl-4 col-md-6'>
                                    <div className='colletion-box'>
                                        <img src={collectionimg3} />

                                        <div className="content">
                                            <div className="content-head">
                                                {/* <img src={profileimg} /> */}

                                                <div className="">
                                                    <h3>Citizen NFT</h3>
                                                    <p>Citizen NFT</p>
                                                </div>
                                            </div>

                                            <div className='btns-wrapper'>
                                                <div className="stake-unstake">
                                                    <button className="active" onClick={() => stakeCitizen()}>Stake</button>
                                                    <button onClick={() => unStakeCitizen()}>Unstake</button>
                                                </div>

                                                <button onClick={() => claimCitizen()}>Claim</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <Modal className="stake-unstake text-center" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <h2>Stake</h2>
                </Modal.Header>
                <Modal.Body>
                    <p>Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt.</p>

                    <form>
                        <select>
                            <option>NFT number 123</option>
                            <option>NFT number 123</option>
                            <option>NFT number 123</option>
                            <option>NFT number 123</option>
                        </select>

                        <div className="btn-wrap">
                            <button className="common-btn-tprnt" type='submit'>Cancel</button>
                            <button type='submit'>Confirm</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal> */}
        </>
    );
};

const mapDispatchToProps = {
    setLoader
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress } = Auth;
    return { publicAddress }
};

export default connect(mapStateToProps, mapDispatchToProps)(Stake);