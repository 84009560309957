import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { React, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";

import TopBar from '../TopBar';
import SuperAdminLinks from '../SuperAdminLinks';
import { web3 } from "../../store/web3";
import { setLoader } from "../../store/actions/Auth";
import { makeTokens } from "../../store/contract/index";

const logo = "/images/main-logo.png";
const arrowswap = "/images/arrow-swap.png";
const sidebararrow = "/images/sidebar-arrow.png";

const Blacklist = (props) => {

    const onOpen = () => SetOPen(true);
    const [Open, SetOPen] = useState(false);
    const [isActive, setIsActive] = useState(false);
    
    const sidebarArrow = () => {
        setIsActive(!isActive);
    };

    const navigate = useNavigate();
    let [usdtAmount, setUsdtAmount] = useState("");
    let [xvrAmount, setXvrAmount] = useState("");

    async function handleOnInput(e) {
        const waitFor = (delay) =>
            new Promise((resolve) => setTimeout(resolve, delay));
        if ([e.target.name] == "usdtAmount") {
            if (parseFloat(e.target.value) >= 0.2) {
                setUsdtAmount(parseFloat(e.target.value));
                setXvrAmount((parseFloat(e.target.value) / 0.2));
            } else if (parseFloat(e.target.value) > 0 && parseFloat(e.target.value) < 0.2) {
                setUsdtAmount(parseFloat(e.target.value));
                setXvrAmount("");
            } else {
                setUsdtAmount("");
                setXvrAmount("");
            }
        }
    }

    async function mintToken(e) {
        try {
            e.preventDefault();

            const { VendingMachineToken, XVRToken, XVRAddress } = await makeTokens();

            const waitFor = (delay) =>
                new Promise((resolve) => setTimeout(resolve, delay));

            let { publicAddress } = props;

            if (usdtAmount == "") {
                EventBus.publish("error", `Enter amount!`);
                return;
            }

            if (usdtAmount < 0.2) {
                EventBus.publish("error", `Insufficient amount!`);
                return;
            }

            if (publicAddress == null || publicAddress == undefined) {
                EventBus.publish("error", `Please connect your wallet!`);
                return;
            }


            let deployer = (await web3.currentProvider.enable())[0];


            const balanceWei = await web3.eth.getBalance(deployer);
            const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

            xvrAmount = web3.utils.toWei(xvrAmount.toString(), "ether");
            usdtAmount = web3.utils.toWei(usdtAmount.toString(), "ether");

            let userBalance = await VendingMachineToken.methods.balanceOf(deployer).call();

            if (parseInt(userBalance) < parseInt(usdtAmount)) return EventBus.publish("error", `Insufficient Balance!`);
            props.setLoader({
                message: "Approval in Progress...",
                status: true,
            });
            await VendingMachineToken.methods.approve(XVRAddress, usdtAmount.toString()).send({
                from: deployer,
            });
            props.setLoader({
                message: "Approval complete...",
                status: true,
            });

            props.setLoader({
                message: "Minting in Progress...",
                status: true,
            });

            let from = publicAddress;
            let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
            if (output == "xdc") {
                from = "0x" + publicAddress.substring(3);
            } else {
                from = publicAddress;
            }

            await web3.eth
                .sendTransaction({
                    from: deployer,
                    value: 0,
                    to: XVRAddress,
                    gas: 5000000,
                    data: XVRToken.methods
                        .mintXVRToken(from, xvrAmount, usdtAmount.toString())
                        .encodeABI(),
                })
                .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
                .on('receipt', async receipt => {
                    setXvrAmount("");
                    setUsdtAmount("");
                    props.setLoader({ status: false });
                    EventBus.publish("success", `XVR Minted Successfully!`);
                });
        } catch (e) {
            console.log(e);
            setXvrAmount("");
            setUsdtAmount("");
            props.setLoader({
                message: "Mint Not Completed...",
                status: false,
            });
            EventBus.publish("error", `Mint Failed`);
        }
    };

    return (
        <>
            <div className="account sidebar">
                <div className= {isActive ? 'left open' : 'left'}>
                    <div className='inner'>
                        <Link className="logo" to="/">
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>

                    <SuperAdminLinks />
                </div>

                <div className={isActive ? 'bg-open active' : 'bg-open'}>
                </div>

                <button className={isActive ? 'sidebar-arrow active' : 'sidebar-arrow'} onClick={sidebarArrow}>
                    <img src={sidebararrow} />
                </button>

                <div className="right">
                    <TopBar />

                    <div className='container'>
                        <div className="form-wrap">
                            <div className="head">
                                <h1>Blacklist user</h1>

                                <p>Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt.</p>
                            </div>

                            <Form >
                                <Form.Group className="mb-2" controlId="formBasicwalletaddress">
                                    <Form.Control
                                        type="text"
                                        name="walletaddress"
                                        placeholder="Wallet address"
                                        //onChange={handleOnInput}
                                    />
                                </Form.Group>

                                <Button className="common-btn" type="submit">
                                    submit
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapDispatchToProps = {
    setLoader
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress } = Auth;
    return { publicAddress }
};

export default connect(mapStateToProps, mapDispatchToProps)(Blacklist);