import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { React, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";

import TopBar from '../TopBar';
import MainLinks from '../MainLinks';
import { web3 } from "../../store/web3";
import { toFixed } from "../../store/numberFormat";
import { setLoader } from "../../store/actions/Auth";
import { makeTokens } from "../../store/contract/index";

const logo = "/images/main-logo.png";
const arrowswap = "/images/arrow-swap.png";
const sidebararrow = "/images/sidebar-arrow.png";

const Dashboard = (props) => {

    const onOpen = () => SetOPen(true);
    const [Open, SetOPen] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const sidebarArrow = () => {
        setIsActive(!isActive);
    };

    const navigate = useNavigate();
    let [xdcAmount, setXdcAmount] = useState("");
    let [xvrAmount, setXvrAmount] = useState("");
    let [usdtAmount, setUsdtAmount] = useState("");

    async function handleOnInput(e) {
        let { xdcPrice } = props;
        const waitFor = (delay) =>
            new Promise((resolve) => setTimeout(resolve, delay));
        if ([e.target.name] == "usdtAmount") {
            if (parseFloat(e.target.value) >= 0.2) {
                setUsdtAmount(parseFloat(e.target.value));
                setXvrAmount(parseFloat(e.target.value) / 0.2);
                let amountAdded = parseFloat(e.target.value);
                let xdcEqual = parseFloat(xdcPrice);
                let xdc = amountAdded / xdcEqual;
                setXdcAmount(xdc);
            } else if (parseFloat(e.target.value) > 0 && parseFloat(e.target.value) < 0.2) {
                setUsdtAmount(parseFloat(e.target.value));
                setXvrAmount("");
                setXdcAmount("");
            } else {
                setUsdtAmount("");
                setXvrAmount("");
                setXdcAmount("");
            }
        }
    }

    async function mintToken(e) {
        try {
            e.preventDefault();

            const { VendingMachineAddress, VendingMachineToken, XVRToken, XVRAddress } = await makeTokens();

            const waitFor = (delay) =>
                new Promise((resolve) => setTimeout(resolve, delay));

            let { publicAddress } = props;

            if (usdtAmount == "") {
                EventBus.publish("error", `Enter amount!`);
                return;
            }

            if (usdtAmount < 0.2) {
                EventBus.publish("error", `Insufficient amount!`);
                return;
            }

            if (publicAddress == null || publicAddress == undefined) {
                EventBus.publish("error", `Please connect your wallet!`);
                return;
            }

            let deployer = (await web3.currentProvider.enable())[0];

            const balanceWei = await web3.eth.getBalance(deployer);
            const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

            xvrAmount = web3.utils.toWei(xvrAmount.toString(), "ether");
            xdcAmount = web3.utils.toWei(xdcAmount.toString(), "ether");

            props.setLoader({
                message: "Minting in Progress...",
                status: true,
            });

            let from = publicAddress;
            let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
            if (output == "xdc") {
                from = "0x" + publicAddress.substring(3);
            } else {
                from = publicAddress;
            }

            await web3.eth
                .sendTransaction({
                    from: deployer,
                    value: xdcAmount.toString(),
                    to: VendingMachineAddress,
                    gas: 5000000,
                    data: VendingMachineToken.methods
                        .swapTokens(from, xvrAmount)
                        .encodeABI(),
                })
                .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
                .on('receipt', async receipt => {
                    setXvrAmount("");
                    setUsdtAmount("");
                    props.setLoader({ status: false });
                    EventBus.publish("success", `XVR Swapped Successfully!`);
                });
        } catch (e) {
            console.log(e);
            setXvrAmount("");
            setUsdtAmount("");
            props.setLoader({
                message: "Swap Not Completed...",
                status: false,
            });
            EventBus.publish("error", `Swap Failed`);
        }
    };

    return (
        <>
            <div className="account sidebar">
                <div className={isActive ? 'left open' : 'left'}>
                    <div className='inner'>
                        <Link className="logo" to="/">
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>

                    <MainLinks />
                </div>

                <div className={isActive ? 'bg-open active' : 'bg-open'}>
                </div>

                <button className={isActive ? 'sidebar-arrow active' : 'sidebar-arrow'} onClick={sidebarArrow}>
                    <img src={sidebararrow} />
                </button>

                <div className="right">
                    <TopBar />

                    <div className='container'>
                        <div className="form-wrap">
                            <div className="head">
                                <h1>Vending machine</h1>

                                <p>Experience the agility and fluidity of trading within the Xverse. With Xverse Swap, diversifying assets becomes more than a transaction—it's an adventure. Effortlessly swap, trade, and transcend the ordinary.</p>
                            </div>

                            <Form onSubmit={mintToken}>
                                <Form.Group className="mb-2" controlId="formBasicstablecoin">
                                    <Form.Control
                                        type="number"
                                        name="usdtAmount"
                                        value={usdtAmount}
                                        placeholder="Amount in USD"
                                        onChange={handleOnInput}
                                    />
                                </Form.Group>

                                <input
                                    type="number"
                                    pattern="[0-9]*"
                                    className="w-full h-12 px-8 mt-8 text-lg outline-none rounded-xl bg-cultured md:h-16 decoration-none"
                                    placeholder={xdcAmount == "" ? "Equivalent amount in XDC" : toFixed(xdcAmount, "price")}
                                    disabled
                                />

                                <img className="mb-2 swap-icon" src={arrowswap} alt="swap-cion"></img>

                                <Form.Group className="mb-2" controlId="formBasicxvr">
                                    <Form.Control
                                        type="number"
                                        name="xvrAmount"
                                        value={xvrAmount}
                                        placeholder="XVR"
                                        disabled
                                    />
                                </Form.Group>

                                <Button className="common-btn" type="submit">
                                    Swap
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapDispatchToProps = {
    setLoader
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, xdcPrice } = Auth;
    return { publicAddress, xdcPrice }
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);