import React, { useState, useEffect } from 'react';
import { Form, Button, Modal} from 'react-bootstrap';
import ProgressBar from "@ramonak/react-progress-bar";
import { connect } from "react-redux";
import { Link, useNavigate , useParams} from "react-router-dom";
import EventBus from 'eventing-bus';
import {web3,makeTokens} from "../../store/contract";
import {setLoader,getSingleCollection,setSingleCollection,mintCollection} from "../../store/actions/Auth";
const alternate = "/images/alternate.jpg";
const decrement = "/images/decrement.png";
const increment = "/images/increment.png";
const MintingCollection = (props) => {

    const {_id} = useParams();
    let [count, setCount] = useState(1);
    let [collection, setCollection] = useState();
    let [maxSupply, setMaxSupply] = useState(0);
    let [totalMint, setTotalMint] = useState(0);
    let [price, setPrice] = useState(0);
    let [token, setToken] = useState();
    let [tokenAddress, setTokenAddress] = useState();

    useEffect(()=>{
        props.setLoader({message:"Load minting engine",status:true});
        props.getSingleCollection({_id});
    },[_id]);

    const makeContract = async (contractAddress) => {
        let {DEPLOYERC721ABI} = await makeTokens();
        let Token = new web3.eth.Contract(DEPLOYERC721ABI,contractAddress);
        let totalMint = await Token.methods.totalSupply().call();
        let maxSupply= await Token.methods.maxSupply().call();
        let price = await Token.methods.priceRecipient().call();
        if(maxSupply) setMaxSupply(maxSupply);
        if(totalMint) setTotalMint(totalMint);
        price = await web3.utils.fromWei(price.toString(),'ether');
        setPrice(price);
        setToken(Token);
    }

    const mintNFT = async (e) => {
        try {
            e.preventDefault()
                let nftIdPrevious =  await token.methods.walletOfOwner(props.publicAddress).call();
                props.setLoader({status:true, message:"Mint nft..."});
                let calculatePrice = Number(price) * Number(count)
                let wPrice = await web3.utils.toWei(calculatePrice.toString(), 'ether');
                web3.eth.sendTransaction({
                    from: props.publicAddress,
                    to: tokenAddress,
                    value: wPrice,
                    gas:5000000,
                    data: token.methods.mintNFT(props.publicAddress,count).encodeABI(),
                  }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                    .on('receipt', async receipt => {
                    let nftIdNew =  await token.methods.walletOfOwner(props.publicAddress).call();
                    let filteredArray = [];
                    if(nftIdPrevious.length > 0){
                        filteredArray = nftIdNew.filter((item) => !nftIdPrevious.includes(item));
                    }
                    else{
                        filteredArray = nftIdNew;
                    }
                    makeContract(tokenAddress);
                    props.mintCollection({publicAddress:props.publicAddress, collectionAddress:tokenAddress, collectionId:_id, count:filteredArray, price, chain:props.chain});
                    }).on('error', e => {
                        console.log("**************** error",e)
                      props.setLoader({status:false});
                      EventBus.publish('error', `Unable to Upload NFT ${e}`);
                });
           } catch (error) {
                props.setLoader({status:false});
                return EventBus.publish('error', error.message);
           }
    }

    useEffect(()=>{
        if(Object.keys(props.singleCollection).length > 0){
            setTokenAddress(props.singleCollection.collectionAddress);
            makeContract(props.singleCollection.collectionAddress)
            setCollection(props.singleCollection);
        }
    },[props.singleCollection])

    function incrementCount() {
        let totalcount = count + 1;
        if(totalcount > 5) return EventBus.publish("error", "You can mint 5 NFT");
        if(totalcount <= 0) setCount(1);
        else setCount(totalcount);
    }
    
    function decrementCount() {
        let totalcount = count - 1;
        if(totalcount <= 0) setCount(1);
        else setCount(totalcount);
    }

    function maxNFT(){
        totalMint = Number(totalMint);
        maxSupply = Number(maxSupply);
        if(maxSupply - totalMint > 5) setCount(5);
        else if(maxSupply - totalMint < 5 && maxSupply - totalMint > 0) setCount(maxSupply - totalMint);
        else if(maxSupply - totalMint <= 0) return EventBus.publish("error", "All Nft Minted"); 
    }

    return(
        <>
            <div className="main-wrapper">
                <div className="container">
                    <div className="collection-detail">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="main-img-wrap">
                                    <img src={collection?.collectionImage ? collection['collectionImage'] : alternate} alt="" />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="collection-main-info minting-collection">
                                    <span>About this collection</span>

                                    <h2> {collection?.collectionName ? collection['collectionName'] :""} </h2>

                                    {/* <p>Adipiscing at in tellus integer. Pellentesque massa placerat duis ultricies lacus. Nisi porta lorem mollis aliquam ut porttitor leo. Venenatis cras..</p> */}

                                    <div className="progress-bar-wrap">
                                        <div className="progressbar-head">
                                            <span><b>{((Number(totalMint)/Number(maxSupply))*100).toFixed(2)} %</b> Minted</span>

                                            <span>{totalMint}/{maxSupply}</span>                       
                                        </div>
                                        <ProgressBar className="progress-bar" completed={(Number(totalMint)/Number(maxSupply))*100} maxCompleted={100} />
                                    </div>

                                    <div className="public-stage-wrapper">
                                        <h3>
                                            Public Stage                 
                                        </h3>

                                        <Form className="inner" onSubmit={mintNFT}>
                                            <div className="limit-wrap">
                                                <div className="info">
                                                    <p>0.035 ETH</p>

                                                    <span>Limit 5 per tx</span>
                                                </div>

                                                <Form.Group className="form-group">
                                                    <img className="decrement" onClick={decrementCount}  src={decrement} alt="" />

                                                    <Form.Control type="number" value={count} />

                                                    <img  className="increment" onClick={incrementCount} src={increment} alt="" />
                                                </Form.Group>
                                                
                                                
                                                <a className="border-btn" onClick={maxNFT}>Max</a>
                                            </div>

                                            <div className="btns" onClick={mintNFT}>
                                                <a>
                                                        Mint
                                                </a>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapDispatchToProps = { setLoader, getSingleCollection, mintCollection };

const mapStateToProps = ({ Auth }) => {
  let { isLogin,publicAddress,singleCollection,chain} = Auth;
  return { isLogin,publicAddress,singleCollection,chain }
}

export default connect(mapStateToProps, mapDispatchToProps)(MintingCollection);