import { Link, useNavigate , useParams} from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import React, { useState, useEffect } from 'react';
import { Button, Modal} from 'react-bootstrap';
import { connect } from "react-redux";
import EventBus from 'eventing-bus';
import {web3,makeTokens} from "../../store/contract";
import { ApiUrl } from "../../store/config";
import { setLoader, getCollections, createCollection} from '../../store/actions/Auth';
import Header from '../Header';
import Footer from '../Footer';
const alternate = "/images/alternate.jpg";
const Collections = (props) => {
    const {_id} = useParams();
    const [file, setFile] = useState(null);
    const [show, setShow] = useState(false);
    const [readFile, setReadFile] = useState();
    const [itemPerRow, setItemPerRow] = useState(10);
    const [next, setNext] = useState(itemPerRow);
    const [fileImage, setFileImage] = useState();
    const [storeData, setStoreData] = useState([]);

    const [collectionPrice, setCollectionPrice] = useState();
    const [collectionName, setCollectionName] = useState();
    const [totalSupply, setTotalSupply] = useState();
    const [baseURI, setBaseURI] = useState();
    const [symbol, setSymbol] = useState();

    
    const fileTypes = ["ZIP"];
    const fileTypes3d = ["PNG","GIF","JPG","JPEG"];

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleChangeImage = (file) => {
        const reader = new FileReader();
        setFileImage(file);
        reader.onload = (e) => {
            setReadFile(e.target.result);
        };
        reader.readAsDataURL(file);
    };

    const handleChange = (file) => {
        setFile(file);
    };

    useEffect(()=>{
        props.setLoader({message:"Load Collections", status:true});
        props.getCollections({_id});
    },[_id]);

    useEffect(()=>{
        if(props.collection.length > 0) {
            setShow(false);
            setStoreData(props.collection);
            setNext(10);
        }
    },[props.collection])

    const handleMoreImage = () => {
        setNext(next + itemPerRow);
    };

    async function getIPFSDataWithPinata(url) {
        return new Promise(async (resolve, reject) => {
          try {
                let data = await fetch(`${ApiUrl}/nft/validateURI`,{
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json', // Set the content type to JSON if you're sending JSON data
                  },
                  body: JSON.stringify({url}), // Convert your data to JSON format if needed
                });
                if (parseInt(data.status) == 200) {
                  return resolve(true);
                }
                else return resolve(false);
          } catch (e) {
            return resolve(false);
          }
        });
    }

    const getIPFSData = (Uri) => {
        return new Promise(async (resolve, reject) => {
            try {
                let data = await fetch(`${Uri}1`);
                if (parseInt(data.status) == 200) {
                    data = await data.json();
                    return resolve(true);
                }
                if (parseInt(data.status) == 404) {
                    data = await fetch(`${Uri}1.json`);
                    if (parseInt(data.status) == 200) {
                        return resolve(true);
                    } else {
                        return resolve(false);
                    }
                }
            } catch (e) {
                return resolve(false);
            }
        });
    }
    
    const createNFT = async (e) => {
        try {
            e.preventDefault()  
            if (!fileImage) return EventBus.publish('error', `Please upload collection image`);
            // if (!file) return EventBus.publish('error', `Please upload assets`);
            if (!collectionName) return EventBus.publish('error', `Please add collection name`);
            if (!symbol) return EventBus.publish('error', `Please add collection symbol`);
            if (!totalSupply) return EventBus.publish('error', `Please add totalSypply`);
            if (!collectionPrice) return EventBus.publish('error', `Please set collections price`);
            if (!baseURI) return EventBus.publish('error', `Please add base uri`);
            if(baseURI && !baseURI.endsWith("/")) baseURI = baseURI + "/";
            let checkUrl;
            if(baseURI.split("/")[2] == 'gateway.pinata.cloud'){
                checkUrl = await getIPFSDataWithPinata(baseURI);
            }else{
                checkUrl = await getIPFSData(baseURI); 
            }
            if(checkUrl == false) return EventBus.publish('error', `Invalid base uri`);

            const balanceWei = await web3.eth.getBalance(props.publicAddress);
            const balanceEth = web3.utils.fromWei(balanceWei, 'ether');
            if(Number(balanceEth) == 0) return EventBus.publish('error', "insufficient balance");
            props.setLoader({ message: 'Collections deploying...', status: true });

            let {DEPLOYERC721BYTECODE,DEPLOYERC721ABI} = await makeTokens();

            let from = (await web3.currentProvider.enable())[0];
            let contract = new web3.eth.Contract(DEPLOYERC721ABI);
            let deploy = await contract.deploy({
                data: DEPLOYERC721BYTECODE,
                arguments: [collectionName, symbol, baseURI,web3.utils.toWei(collectionPrice.toString(), 'ether'),totalSupply],
                gas:5000000,
            });

            await deploy
                .send({ from })
                .on("transactionHash", (hash) =>
                    console.log(`************** deploy contract hash = ${hash}`)
                )
                .on("receipt", async (receipt) => {
                    const formData = new FormData();
                    formData.append('collections', fileImage);
                    // formData.append('assets', file);
                    formData.append('collectionName', collectionName);
                    formData.append('baseUri', baseURI);
                    formData.append('collectionSymbol', symbol);
                    formData.append('totalSypply', totalSupply);
                    formData.append('collectionPrice', collectionPrice);
                    formData.append('collectionAddress', receipt['contractAddress']);
                    formData.append('publicAddress', props.publicAddress);
                    formData.append('storeId', _id);
                    props.createCollection(formData);
                    // props.setLoader({ message: 'Collections Deploying Please Wait...', status: false });
                })
        } catch (error) {
            console.log("********************** error", error);
            props.setLoader({ status: false });
            return EventBus.publish('error', error.message);
        }
    }

    return(
        <>
        <Header />
            <div className="main-wrapper">
                <div className="container">
                    <div className="main-head">
                        <div className="collection-main-head">
                            <img src={props.singleStore ? props.singleStore.storeImage : alternate} alt="" />
                            <div>
                                <h1>{props.singleStore ? props.singleStore.storeName : ""}</h1>

                                <p>{props.singleStore ? props.singleStore.totalCollection : ""} Collections</p>
                            </div>
                        </div>

                       {props.isLogin && <button  onClick={handleShow} className="border-btn">Add Collection</button> }
                    </div> 
                </div>

                <div className="collection-wrap">
                    <div className="container">
                        <div className="row">
                        {storeData.length > 0 && storeData.slice(0, next).map(item=> 
                            <Link to={`/mintingcollection/${item['_id']}`} className="col-lg-4 col-md-6">
                                <div className="collection-box">
                                    <img src={item['collectionImage'] ? item['collectionImage'] : alternate} alt="" />

                                    <div className="content">
                                        <div className="collection-info">
                                            <img src={item['collectionImage'] ? item['collectionImage'] : alternate} alt="" />

                                            <div className="info">
                                                <h3>{item['collectionName']}</h3>

                                                <p>{item['totalMint']} Nfts</p>
                                            </div>
                                        </div>
                                        {/* <div chttps://d1bbqgi1ue6lx6.cloudfront.net/lassName="btns">
                                            <a href="#">
                                                <img src="images/bag.png" alt="" />
                                                Purchase Store
                                            </a>
                                        </div> */}
                                    </div>
                                </div>
                            </Link>
                            )}
                        </div>

                        <div class="load-more-btn text-center">
                            {next < storeData?.length && (
                                <a className="border-btn" onClick={handleMoreImage}>
                                    Load More
                                </a>
                            )}
                        </div>
                        
                    </div>
                </div>
            </div>
        <Footer />
            <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <h2>Add Collection</h2>
            </Modal.Header>
            <Modal.Body>
            {/* <div class="upload-card">
                <div className="drop_box">
                    <h4>Drag and drop ZIP file here</h4>
                    <FileUploader
                        multiple={false}
                        handleChange={handleChange}
                        name="file"
                        types={fileTypes}
                    />
                    <p>{file ? `File name: ${file.name}` : "no files uploaded yet"}</p>
                </div>
            </div> */}
            <div class="upload-card">
                <div className="drop_box">
                    <h4>Drag and drop Collection Image file here</h4>
                    <FileUploader
                        multiple={false}
                        handleChange={handleChangeImage}
                        name="files"
                        types={fileTypes3d}
                    />
                    <p>{fileImage ? `${fileImage.name}` : "no files uploaded yet"}</p>
                </div>
            </div>
            <form onSubmit={createNFT}>
                <input type="text" placeholder='Add Collection Name' maxLength={50} onChange={e=>setCollectionName(e.target.value)} value={collectionName}/>
                <input type="text" placeholder='Set price (ETH)' onChange={e=>setCollectionPrice(e.target.value)} value={collectionPrice}/>
                <input type="text" placeholder='Symbol' onChange={e=>setSymbol(e.target.value)} value={symbol}/>
                <input type="text" placeholder='Total Supply' onChange={e=>setTotalSupply(e.target.value)} value={totalSupply}/>
                <input type="text" placeholder='Base URL' onChange={e=>setBaseURI(e.target.value)} value={baseURI}/>
                <button type='submit'>Add Collection</button>
            </form>
            </Modal.Body>
        </Modal>
        </>
    );
};

const mapDispatchToProps = { setLoader, getCollections,createCollection };

const mapStateToProps = ({ Auth }) => {
  let { singleStore,collection,isLogin,publicAddress} = Auth;
  return { singleStore,collection,isLogin,publicAddress }
}

export default connect(mapStateToProps, mapDispatchToProps)(Collections);