import React, { useState, useEffect } from 'react';
import { NavLink, Outlet, useLocation } from "react-router-dom";

const approvekyc = "/images/approvekyc.png";
const approve = "/images/approve.png";
const backlist = "/images/backlist.png";

const SuperAdminLinks = (props) => {

    const location = useLocation();

    return (
        <>
            <ul className="navbar-nav">
                <li className="nav-item">
                    <NavLink className={location.pathname === '/approvekyc' ? 'active' : ''} to="/approvekyc"><img src={approvekyc}/>Approve KYC</NavLink>
                </li>

                <li className="nav-item">
                    <NavLink className={location.pathname === '/approve' ? 'active' : ''} to="/approve"><img src={approve}/>Approve</NavLink>
                </li>

                <li className="nav-item">
                    <NavLink className={location.pathname === '/blacklist' ? 'active' : ''} to="/blacklist"><img src={backlist}/>Backlist</NavLink>
                </li>
            </ul>
        </>
    )
}

export default SuperAdminLinks;

