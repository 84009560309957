import React from 'react';
import Slider from "react-slick";
import Header from '../Header';
import Footer from '../Footer';
const Faq = () => {

    let settings = {
        centerMode: true,
        centerPadding: '60px',
        slidesToShow: 1,
        arrows:false,
        dots:false,
      };

    return(
        <>
        <Header />

        <section class="common-content">
            <div class="container text-center">
                <div className='content'> 

                
                <h2>What is Xverse?</h2>
                <p>Xverse is an immersive Xverse designed for exploration, gaming, trading,  and socializing. It's a virtual world where users can customize their experience, engage in diverse activities, and shape their own digital realities.</p>

                <h2>How do I get started in Xverse?</h2>
                <p>Getting started is easy! Simply create an account, customize your avatar, and dive into the virtual world. Explore landscapes, play games, and connect with other users. The adventure begins with just a few clicks.  </p>

                <h2>What makes Xverse unique?</h2>
                <p>Xverse stands out for its commitment to user empowerment. You can define your own rules, shape your virtual reality, and explore a constantly evolving Xverse. It's a space where creativity and individuality flourish.</p>

                <h2>Can I trade items in Xverse?</h2>
                <p>Absolutely! Xverse features a dynamic virtual economy. Engage in trading activities, showcase your unique items, and participate in the vibrant marketplace to enhance your virtual existence.</p>

                <h2>Are there safety measures in place for users?</h2>
                <p>Yes, your safety is our priority. Xverse employs robust security measures to ensure a safe and enjoyable experience for all users. We continuously update our systems to provide a secure virtual environment.</p>

                <h2>What kind of accessories are available in Xverse?</h2>
                <p>From avatar upgrades to virtual real estate, Xverse offers a range of exclusive accessories. Personalize your virtual identity with unique outfits, design your dream spaces, and enhance your gameplay with cutting-edge gadgets.</p>

                <h2>How often is Xverse updated?</h2>
                <p>Xverse is a living, breathing entity. We roll out regular updates, host exciting events, and actively engage with our community to ensure a dynamic and ever-evolving Xverse experience. Stay tuned for a constant stream of new features and adventures. </p>
                </div>
            </div>
        </section>

        <Footer />
    </>
    );
};

export default Faq;