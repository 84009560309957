import React, { useState, useEffect } from 'react';
import { Link, NavLink, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import EventBus from 'eventing-bus';
import {web3,makeTokens} from "../../store/contract";
import { setLoader, getAllNfts, transferNft } from '../../store/actions/Auth';
import Header from '../Header';
import Footer from '../Footer';

const alternate = "/images/alternate.jpg";
const zipFile = "/images/pngtree-zip.png";

const Marketplace = (props) => {

    const [collections, setCollections] = useState([]);
    const [itemPerRow, setItemPerRow] = useState(10);
    const [next, setNext] = useState(itemPerRow);

    useEffect(() => {
        props.getAllNfts({});
    }, []);

    useEffect(()=>{
        if(props.setAllNfts?.length > 0) setCollections(props.setAllNfts);
    },[props.setAllNfts])

    const handleMoreImage = () => {
        setNext(next + itemPerRow);
    };

    const handleCollectionImageError = (idx) => {
        collections[idx]['profileImage'] = alternate;
        setCollections([...collections]);
    }

    const buyNft = async (nftId,price,tokenAddress) => {
        try {
            if(!props.publicAddress) return EventBus.publish('error',`Please connect wallet`);
            let wPrice = await web3.utils.toWei(price.toString(), 'ether');
            const balanceWei = await web3.eth.getBalance(props.publicAddress);
            const balanceEth = web3.utils.fromWei(balanceWei, 'ether');
            if(Number(balanceEth) < Number(price)) return EventBus.publish('error', "insufficient balance");
            props.setLoader({ message: 'Buy nft...', status: true });
            let {DEPLOYERC721ABI,MarketplaceToken,MarketplaceAddress} = await makeTokens();
                web3.eth.sendTransaction({
                    from: props.publicAddress,
                    to: MarketplaceAddress,
                    value: wPrice,
                    data: MarketplaceToken.methods.createMarketSale(nftId,tokenAddress).encodeABI(),
                  }).on('transactionHash', (hash) => console.log(`************ createnftIdhash =`, hash))
                    .on('receipt', async receipt => {
                    props.transferNft({nftId, tokenAddress:tokenAddress, publicAddress:props.publicAddress})
                    }).on('error', e => {
                      props.setLoader({status:false});
                      EventBus.publish('error', `Unable to List NFT ${e}`);
                });
        } catch (error) {
            console.log("********************** error",error);
            props.setLoader({status:false});
            return EventBus.publish('error', error.message);
        }
    }


    return(
        <>
        <Header />
        <section class="market">
        <div class="container">
            <div class="row title-row">
                <div class="col-12 text-center">
                    <h1><span>Create. Sell. Buy.</span></h1>
                    <p>Let's explore our market of 3d assets and experience with Xverse.</p>
                </div>
            </div>
            <div class="row detail-row">
            {collections?.length > 0 && collections.map((item, idx)=>
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="thumbnail">
                        {/* <img key={idx} src={zipFile} alt=""  onError={() => handleCollectionImageError(idx)}/> */}
                            <img key={idx} src={item['image3D'] ? item['image3D'] : alternate} alt=""  onError={() => handleCollectionImageError(idx)}/>
                        </div>
                        <h5>{item['title'] ? item['title'] : ""}</h5>
                        <ul>
                            <li class="price"> {item['price'] ? item['price'] : 0} XDC</li>
                            <li><button  class="buy-now-btn" onClick={()=>buyNft(item['nftId'],item['price'],item['tokenAddress'])}>Buy Now</button></li>
                        </ul>
                    </div>
                </div>
            )}
            </div>
            {next < collections?.length && (
            <div class="load-more-btn">
                <a onClick={handleMoreImage}>Load More</a>
            </div>
            )}
        </div>
    </section>
    <Footer />
    </>
    );
};

const mapDispatchToProps = { getAllNfts, setLoader, transferNft };

const mapStateToProps = ({ Auth }) => {
  let { setAllNfts,publicAddress} = Auth;
  return { setAllNfts,publicAddress }
}

export default connect(mapStateToProps, mapDispatchToProps)(Marketplace);