import { PURGE } from "redux-persist";
import { setToken } from "../axios";
import { tokenVerify } from "../jwtVerify";

var initialState = {
  isLogin: tokenVerify() ? true : false,
  auth: localStorage.getItem("token"),
  publicAddress: localStorage.getItem("publicAddress"),
  chain: 51,
  isLoader: { message: "Please Wait...", status: false },
  network: "xdc",
  nonce: "",
  isNonce: true,
  isChangeNetwork: false,
  singleNft: {},
  userNfts: [],
  setAllNfts: [],
  store: [],
  singleStore: {},
  singleCollection: {},
  collection: [],
  kycStatus: false,
  kycGStatus: "",
  kycValue: [],
  xdcPrice: "",
  userRole: "citizen",
  kycSubmitted: {},
};


const Auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    /*========== LOGIN REDUCERS ============= */

    case "SET_LOGIN":
      localStorage.setItem("token", payload);
      return {
        ...state,
        auth: payload,
        isLogin: true,
        isNonce: true
      };

    case "SET_NETWORK":
      localStorage.setItem("network", payload)
      return {
        ...state,
        network: payload,
        isChangeNetwork: true
      };

    case "SET_CHANGE_NETWORK":
      return {
        ...state,
        isChangeNetwork: payload
      };

    case "LOGOUT":
      localStorage.removeItem("token");
      localStorage.removeItem("publicAddress");
      localStorage.removeItem("chain");
      localStorage.removeItem("network");
      setToken();
      return {
        ...state,
        publicAddress: "",
        auth: "",
        isLogin: false,
      };

    case "SET_ADDRESS":
      localStorage.setItem("publicAddress", payload['publicAddress'])
      localStorage.setItem("chain", payload['chain'])
      return {
        ...state,
        publicAddress: payload['publicAddress'],
        chain: payload['chain']
      };

    case "SET_NONCE":
      return {
        ...state,
        nonce: payload,
        isChangeNetwork: true,
        isNonce: false
      }
    /*========== LOADER REDUCERS ============= */

    case "SET_LOADER":
      return {
        ...state,
        isLoader: payload,
      };

    /*========== COLLECTIONS REDUCERS ============= */
    case "SET_SINGLE_NFT":
      return {
        ...state,
        singleNft: payload,
      };

    case "SET_USER_NFTS":
      return {
        ...state,
        userNfts: payload
      }

    case "SET_ALL_NFTS":
      return {
        ...state,
        setAllNfts: payload
      }
    /*========== STORE REDUCERS ============= */
    case "SET_STORE":
      return {
        ...state,
        store: payload
      }

    case "SET_SINGLE_STORE":
      return {
        ...state,
        singleStore: payload,
      }

    case "SET_SINGLE_COLLECTIONS":
      return {
        ...state,
        singleCollection: payload,
      }

    case "SET_COLLECTIONS":
      return {
        ...state,
        collection: payload,
      }

    /*========== DAO REDUCERS ============= */

    case "SET_PROPOSALS":
      return {
        ...state,
        proposalList: payload,
      };

    /*========== KYC REDUCERS ============= */

    case "SET_XDC_PRICE":
      return {
        ...state,
        xdcPrice: payload,
      };

    case "SET_KYC_STATUS":
      return {
        ...state,
        kycStatus: payload,
      };

    case "SET_G_KYC_STATUS":
      return {
        ...state,
        kycGStatus: payload,
      };

    case "SET_KYC":
      return {
        ...state,
        kycValue: payload,
      };

    case "KYC_RESPONSE":
      return {
        ...state,
        kycSubmitted: payload,
      };

    case "SET_ROLE":
      localStorage.setItem("userRole", payload);
      return {
        ...state,
        userRole: payload,
      }; 

    default:
      return state;
  }
};

export default Auth;
