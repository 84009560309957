import React, { useState, useEffect } from 'react';
import { Link, NavLink, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import EventBus from 'eventing-bus';
import { web3 } from "../store/contract";
import { connectMetamask, getNetworkSymbol} from "../store/walletConnect";
import { networkType } from "../store/config";

import { logout, setAddress, setNetwork, getNonce, login } from '../store/actions/Auth';
const logo = "/images/main-logo.png";

const Header = (props) => {

    let [login,setLogin] = useState(false);
    const handleWalletChanges = (network) => {
        if(network){
            connectMetamask(network, networkType)
            .then(async ({address}) => {
              if(address){
                  let chain = await web3.eth.getChainId();
                  chain = web3.utils.hexToNumber(chain);
                  chain = Number(chain);
                  props.setAddress({publicAddress:address,chain});
                  props.getNonce({publicAddress:address,chain});
              }
            })
            .catch((error) => {
              return EventBus.publish("error", `failed to connect wallet: ${error}`); 
          });
        }
    };

    useEffect(()=>{
        if (typeof window.ethereum !== 'undefined') {
            // Register the event listener when the component mounts
            window.ethereum.on('chainChanged',()=>{props.logout()});
            // Listen for accounts changed event
            window.ethereum.on('accountsChanged',()=>{props.logout()});
            // Clean up the event listener when the component unmounts
            // return () => {
            //     window.ethereum.removeListener('chainChanged', setLogin(false));
            //     window.ethereum.removeListener('accountsChanged',setLogin(false))
            // };
        }
    },[]);

    const loginWallet = async(nonce) => {
        try {
          const accounts = await web3.eth.getAccounts();
          const address = accounts[0];
          if(address){
            let chain = await web3.eth.getChainId();
            chain = web3.utils.hexToNumber(chain);
            chain = Number(chain);
            const signature = await web3.eth.personal.sign(`marketplace signature ${nonce}`, address);
            if(address && signature) props.login({ address, signature, nonce, chain})
          }
        } catch (error) {
            return EventBus.publish("error", `failed to create signature: ${error}`); 
        }
    }

    useEffect(()=>{
        if(props.nonce && props.isChangeNetwork === true) loginWallet(props.nonce);
    },[props.nonce])
    return(
        <>
            <header>
        <nav class="navbar navbar-expand-xl navbar-light p-0">
            <div class="container">
                {/* <a class="navbar-brand p-0 m-0" href="#">Metaverse</a> */}
                <Link to={"/"} class="navbar-brand p-0 m-0"  >
                    <img src={logo} alt="logo" />
                </Link>
                <button onclick="myFunction(this)" class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="bar1"></span>
                    <span class="bar2"></span>
                    <span class="bar3"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ms-auto">
                        <li class="nav-item">
                            {/* <a class="nav-link active" href="#">Home</a> */}
                            <Link to={"/"} class="nav-link active" >Home</Link>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">Resource</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">Pricing</a>
                        </li>
                        <li class="nav-item">
                            <Link to={"/dashboard"} class="nav-link" >Dashboard</Link>
                        </li>
                        <li class="nav-item">
                            <Link to={"/marketplace"} class="nav-link" >Marketplace</Link>
                        </li>
                        <li class="nav-item">
                            <a href={"https://game.xvrse.ai"} target='_blank' class="nav-link" >Xverse</a>
                        </li>
                        
                        <li class="nav-item">
                            <Link class="nav-link" to={"/store"} >Store</Link>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="/about">About Us</a>
                        </li>
                        {props.isLogin == true &&
                          <li class="nav-item">
                              <Link to={"/listing"} class="nav-link" href="#">Listing</Link>
                          </li>
                        }
                        <li class="nav-item">
                            <NavLink className="nav-link wallet-btn" onClick={()=>handleWalletChanges(props.network)}>
                                {props?.publicAddress ? props.publicAddress.slice(0,6)+"..."+props.publicAddress.slice(38,42) : "Connect Wallet"}
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
    </>
    );
};

const mapDispatchToProps = { logout, setAddress, setNetwork,getNonce, login };

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, network, nonce, isLogin,isNonce, isChangeNetwork} = Auth;
  return { publicAddress, network, nonce, isLogin,isNonce, isChangeNetwork }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);