import {React , useState} from 'react';
import {Link, NavLink, useNavigate } from "react-router-dom";
import {Form, Button} from 'react-bootstrap';

const logo = "/images/main-logo.png";
const arrow = "/images/arrow.png";
const eye = "/images/eye.png";

const AdminLogin = () => {

    let settings = {
        centerMode: true,
        centerPadding: '60px',
        slidesToShow: 1,
        arrows:false,
        dots:false,
      };

    return(
        <>
            <div className="account signin">
                <div class="left">
                    <div className='inner'>
                        <Link  className="logo" to="/">
                            <img src={logo} alt="logo" />
                        </Link>
                        
                        <div className="head">
                            <h1>Welcome</h1>

                            <p>Admin access to manage the Xverse dApp</p>
                        </div>

                        <Form>
                            <Button className="common-btn" type="submit">
                                Log in with XDCPay wallet
                                <img src={arrow} alt="arrow" />
                            </Button>
                        </Form>
                    </div>
                </div>

                <div className="right">
                    <span>log in</span>
                </div>
            </div>
        </>
    );
};

export default AdminLogin;