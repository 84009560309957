import App from './App';
import React from 'react';
import rootSaga from './store/sagas';
import { logger } from 'redux-logger';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import rootReducer from './store/reducers';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware } from 'redux';

import './index.css';
import "../node_modules/bootstrap/dist/js/bootstrap.js";
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";

// const root = ReactDOM.createRoot(document.getElementById('root'));

const root = ReactDOM.createRoot(document.getElementById('root'));

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware, logger));

sagaMiddleware.run(rootSaga);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
