
import React, { useState, useEffect } from 'react';
import { Button, Modal} from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import EventBus from 'eventing-bus';
import { connect } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import Header from '../Header';
import Footer from '../Footer';
import {web3,makeTokens} from "../../store/contract";
import { setLoader, createStore, getStore} from '../../store/actions/Auth';
const alternate = "/images/alternate.jpg";
const Store = (props) => {
    
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [fileImage, setFileImage] = useState();
    const [readFile, setReadFile] = useState();
    const [name, setName] = useState();
    const [storeData, setStoreData] = useState([]);
    const [itemPerRow, setItemPerRow] = useState(10);
    const [next, setNext] = useState(itemPerRow);
    
    const fileTypes = ["PNG","GIF","JPG","JPEG"];

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleChangeImage = (file) => {
        const reader = new FileReader();
        setFileImage(file);

        reader.onload = (e) => {
            setReadFile(e.target.result);
        };
        reader.readAsDataURL(file);
    };

    const addStore = async () => {
        try {
            if(!fileImage) return EventBus.publish('error', "please upload file");
            if(!name) return EventBus.publish('error', "please set name");
            props.setLoader({message:"Create Store",status:true});
            const formData = new FormData();
            formData.append('store', fileImage);
            formData.append('storeName', name);
            props.createStore(formData);
        }  catch (error) {
            props.setLoader({status:false});
            return EventBus.publish('error', error.message);
       }
    }
    
    useEffect(()=>{
        if(props.store && props.store.length > 0) {
            setFileImage();
            setReadFile();
            setName();
            setShow(false);
            setStoreData(props.store);
            setNext(10);
        }
    },[props.store])

    const handleMoreImage = () => {
        setNext(next + itemPerRow);
    };

    useEffect(()=>{
        props.setLoader({message:"Load Store", status:true});
        props.getStore()
    },[])
    return(
        <>
        <Header />
            <div className="main-wrapper">
                <div className="container">
                    <div className="main-head">
                        <div className="">
                            <h1>Store</h1>

                            <p>Let's explore our market of NFT stores and experience with Xverse.</p>
                        </div>

                       {props.isLogin && <button onClick={handleShow} className="border-btn">Store</button> }
                    </div> 
                </div>

                <div className="collection-wrap">
                    <div className="container">
                        <div className="row">
                            {storeData && storeData.length > 0 && storeData.slice(0, next).map(item=> 
                            <Link to={`/collections/${item['_id']}`} className="col-lg-4 col-md-6">
                                <div className="collection-box">
                                    <img src={item['storeImage'] ? item['storeImage'] : alternate} alt="" />

                                    <div className="content">
                                        <div className="collection-info">
                                            <img src={item['storeImage'] ? item['storeImage'] : alternate} alt="" />

                                            <div className="info">
                                                <h3>{item['storeName']}</h3>

                                                <p>{item['totalCollection']} Collections</p>
                                            </div>
                                        </div>
                                        {/* <div chttps://d1bbqgi1ue6lx6.cloudfront.net/lassName="btns">
                                            <a href="#">
                                                <img src="images/bag.png" alt="" />
                                                Purchase Store
                                            </a>
                                        </div> */}
                                    </div>
                                </div>
                            </Link>
                            )}
                        </div>

                        <div class="load-more-btn text-center">
                            {next < storeData?.length && (
                                        <a className="border-btn" onClick={handleMoreImage}>
                                            Load More
                                        </a>
                                    )}
                        </div>
                        
                    </div>
                </div>
            </div>
        <Footer />
            <Modal className="store-modal" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <h2>Create Store</h2>
            </Modal.Header>
            <Modal.Body>
            <img src={readFile ? readFile : alternate} alt="" />

            <div class="upload-card py-5 mt-3">
                <div className="drop_box">
                    <FileUploader
                        multiple={false}
                        handleChange={handleChangeImage}
                        name="files"
                        type="file"
                        types={fileTypes}
                    />
                    {/* <p>{fileImage ? `${fileImage.name}` : "no files uploaded yet"}</p> */}
                </div>
            </div>
            <div class="py-5 mt-3">
                <form>
                    <input type="text" placeholder='Please enter name' onChange={e=>setName(e.target.value)} maxLength={30}/>
                </form>
            </div>


            <div className='btns'>
                <a className="text-white w-100 text-center" onClick={addStore}>Create Store</a>
            </div>
            </Modal.Body>
        </Modal>
        </>
    );
};

const mapDispatchToProps = { setLoader, createStore, getStore };

const mapStateToProps = ({ Auth }) => {
  let { store,isLogin,publicAddress} = Auth;
  return { store,isLogin,publicAddress }
}

export default connect(mapStateToProps, mapDispatchToProps)(Store);