import React from 'react';

const Footer = () => {
    return(
        <>
          <footer>
        <div class="container">
            <div class="row">
                <div class="col-lg-5 mb-lg-0 mb-4">
                    <a href="#" class="footer-logo">Xverse</a>
                    <p>Journey Beyond: Xverse – Where Dreams Take Digital Flight</p>
                </div>
                <div class="col-lg-2 mb-lg-0 mb-4">
                    <h5>About Us</h5>
                    <ul>
                        <li><a href="/about">About MetaV</a></li>
                        <li><a href="/contact">Contact Us</a></li>
                        <li><a href="/faq">FAQ</a></li>
                        <li><a href="#">Privacy Policy</a></li>
                        <li><a href="#">Terms of Service</a></li>
                    </ul>
                </div>
                <div class="col-lg-2 mb-lg-0 mb-4">
                    <h5>Services</h5>
                    <ul>
                        <li><a href="#">NFT Marketplace</a></li>
                        <li><a href="#">Virtual Concert</a></li>
                        <li><a href="#">Gaming</a></li>
                        <li><a href="#">Artwork</a></li>
                        <li><a href="#">Online Travel</a></li>
                    </ul>
                </div>
                <div class="col-lg-3">
                    <h5>Contact</h5>
                    <ul class="social-links">
                        <li><a href="#"><img src="images/linkedIn.png" alt="" /></a></li>
                        <li><a href="#"><img src="images/twitter.png" alt="" /></a></li>
                        <li><a href="#"><img src="images/Instagram.png" alt="" /></a></li>
                        <li><a href="#"><img src="images/Facebook.png" alt="" /></a></li>
                    </ul>
                    <p class="copyright">2022 © Xverse.com All right reserved.</p>
                </div>
            </div>
        </div>
    </footer>
    </>
    );
};

export default Footer;