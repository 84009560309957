import {React , useState} from 'react';
import {Link, NavLink, useNavigate } from "react-router-dom";
import {Form, Button} from 'react-bootstrap';

const logo = "/images/main-logo.png";
const arrow = "/images/arrow.png";
const eye = "/images/eye.png";

const SignUp = () => {
    const navigate = useNavigate();
    const [key, setKey] = useState('home');
    return(
    <>
        
        <div className="account signup">
            <div class="left">
                <div className='inner'>
                    <Link  className="logo" to="/">
                        <img src={logo} alt="logo" />
                    </Link>
                    
                    <div className="head">
                        <h1>Sign up</h1>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore </p>
                    </div>

                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Control type="text" placeholder="Name" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control type="email" placeholder="Email" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPswrd">
                            <Form.Control type="password" placeholder="Password" />
                            <img className="eye" src={eye} alt="eye" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPswrd">
                            <Form.Control type="password" placeholder="Retype Password" />
                            <img className="eye" src={eye} alt="eye" />
                        </Form.Group>

                        <Button className="common-btn" type="submit">
                            Get started
                            <img src={arrow} alt="arrow" />
                        </Button>

                        <Link to="/login">Already have an account? Log in</Link>
                    </Form>
                </div>
            </div>

            <div className="right">
                <span>sign up </span>
            </div>
        </div>
        
    </>
    );
};
export default SignUp;