import Web3 from 'web3';
export const connectMetamask = async (network, isTestnet) => {
    try {
      // Check if Metamask is installed
      if (typeof window.ethereum !== 'undefined') {
        const web3 = new Web3(window.ethereum);
        await window.ethereum.enable();
  
        // Define network details
        let rpcUrl, chainId, chainName, nativeCurrency, blockExplorerUrl;
        switch (network) {
          case 'xdc':
            rpcUrl = isTestnet
              ? ['https://erpc.apothem.network','https://earpc.apothem.network']
              : ['https://erpc.xinfin.network','https://earpc.xinfin.network'];
            chainId = isTestnet ? '0x33' : '0x32';
            chainName = isTestnet ? 'XDC Testnet' : 'XDC Mainnet';
            nativeCurrency = {
              name: isTestnet ? 'TXDC' : 'XDC',
              symbol: isTestnet ? 'TXDC' : 'XDC',
              decimals: 18,
            };
            blockExplorerUrl = isTestnet
              ? 'https://explorer.apothem.network'
              : 'https://xdc.blocksscan.io/';
            break;
          default:
            return Promise.reject(new Error('Invalid network'));
        }
            const netId = await web3.eth.net.getId();
            if (netId.toString() !== chainId.toString()) {
                await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                    {
                    chainId: chainId,
                    chainName: chainName,
                    nativeCurrency: nativeCurrency,
                    rpcUrls: rpcUrl,
                    blockExplorerUrls: [blockExplorerUrl],
                    },
                ],
                });
            }
            else {
                // Switch to the selected network
                await web3.eth.switchNetwork(chainId);
            }

            const accounts = await web3.eth.getAccounts();
            const address = accounts[0];
            return Promise.resolve({address});
       
      } else {
        console.log('Metamask is not installed');
        return Promise.reject(new Error('Metamask is not installed'));
      }
    } catch (error) {
      console.log('Error connecting to Metamask:', error);
      return Promise.reject(error.message);
    }
};

export const getNetworkSymbol = (networkId) => {
    const networkMap = {
      '1': 'eth', // Ethereum Mainnet
      '11155111': 'eth', // Sepolia Testnet
      '56': 'bnb', // Binance Smart Chain Mainnet
      '97': 'bnb', // Binance Smart Chain Testnet (BSC Testnet)
      '43114': 'avax', // Avalanche Mainnet
      '43113': 'avax', // Avalanche Fuji Testnet
      '50': 'xdc', // xdc Mainnet
      '51': 'xdc', // xdc Testnet
    };
  
    return networkMap[networkId] || 'Unknown';
};
  