import React from 'react';
import Slider from "react-slick";
import Header from '../Header';
import Footer from '../Footer';
const Contact = () => {

    let settings = {
        centerMode: true,
        centerPadding: '60px',
        slidesToShow: 1,
        arrows:false,
        dots:false,
      };

    return(
        <>
        <Header />

        <section class="common-content">
            <div class="container text-center">
                <div className='content'>
                    <h2>Contact Us</h2>
                    <p>At Xverse, your feedback and inquiries are valuable to us. We're here to help, whether you have questions about our Xverse, need assistance, or collaborate. Feel free to reach out to our dedicated support team via the contact form below.</p>

                    <p>Your journey in the virtual realm matters to us, and we're committed to ensuring your experience with Xverse is seamless. Thank you for being a part of our digital community, and we look forward to hearing from you soon. </p>
                </div>
            </div>
        </section>

        <Footer />
    </>
    );
};

export default Contact;