import axios from 'axios';
import jwt_decode from 'jwt-decode';
import EventBus from 'eventing-bus';
import { setToken } from "../axios";
import { all, takeEvery, call, put, take } from 'redux-saga/effects';
import {
  setNonce, setLogin, setLoader, setChangeNetwork, setAllNfts, setUserNft, setSingleNft, setAddress, setStore, setCollections, setSingleStore,
  setProposals, setSingleCollection, setKycStatus, setKyc, setGKycStatus, kycResponse, setXdcPrice, setRole
} from '../actions/Auth';

function* getNonce({ payload }) {
  const { error, response } = yield call(getCall, `/users/getNonce/${payload['publicAddress']}/${payload['chain']}`);
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) {
    yield put(setNonce(response['data']['body']));
  }
};

/*========== LOGIN FUNCTIONS =============*/

function* login({ payload, history }) {
  const { error, response } = yield call(postCall, { path: '/users/loginWithMetaMask', payload });
  if (error) {
    // yield put({ type: "TOGGLE_LOGIN" });
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    const decoded = jwt_decode(response["data"]["body"]["token"]);
    const currentTime = Date.now() / 1000; // Get current time in seconds
    if (decoded.exp < currentTime) return EventBus.publish("error", "Token is expire");
    if (decoded["role"] !== "user") return EventBus.publish("error", "Can't login through User account ");
    setToken(response["data"]["body"]["token"]);
    yield put(setAddress({ publicAddress: payload['address'], chain: payload['chain'] }));
    yield put(setLogin(response['data']['body']['token']));
    yield put(setChangeNetwork(false));
    EventBus.publish("success", response['data']['message']);
  }
};


/*========== Create NFT FUNCTIONS =============*/
function* createNFT({ payload }) {
  const { error, response } = yield call(postCall, { path: '/uploadAssets', payload });
  if (error) {
    yield put(setLoader({ status: false }));
    EventBus.publish('error', error['response']['data']['message']);
  }
  else if (response) {
    yield put(setUserNft(response["data"]["body"]));
    yield put(setLoader({ status: false }));
    EventBus.publish('success', response['data']['message']);
  }
};

/*========== Mint NFT FUNCTIONS =============*/
function* mintNFT721({ payload }) {
  const { error, response } = yield call(postCall, { path: '/nft/mintNft721', payload });
  if (error) {
    yield put(setLoader({ status: false }));
    EventBus.publish('error', error['response']['data']['message']);
  }
  else if (response) {
    yield put(setLoader({ status: false }));
    EventBus.publish('success', response['data']['message']);
  }
};


export function* getUserNft({ payload }) {
  const { error, response } = yield call(getCall, `/getUserAssets/${payload['publicAddress']}`);
  if (error) {
    yield put(setLoader({ status: false }));
    yield put(setUserNft([]));
    // EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put(setLoader({ status: false }));
    yield put(setUserNft(response["data"]["body"]));
    // EventBus.publish("success", response["data"]["message"]);
  }
}

/*========== MARKETPLACE FUNCTIONS =============*/

// getDetailNft
export function* getSingleNftDetails({ payload }) {
  const { error, response } = yield call(getCall, `/nft/getDetailNft/${payload['id']}`);
  if (error) {
    yield put(setSingleNft({}));
    yield put(setLoader({ status: false }));
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put(setSingleNft(response["data"]["body"]));
    yield put(setLoader({ status: false }));
    EventBus.publish("success", response["data"]["message"]);
  }
}

// list nft
export function* listNft({ payload }) {
  const { error, response } = yield call(postCall, { path: "/listAsset", payload });
  if (error) {
    yield put(setLoader({ status: false }));
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put(setUserNft(response["data"]["body"]));
    yield put(setLoader({ status: false }));
    EventBus.publish("success", response["data"]["message"]);
  }
}

// unlist nft
export function* unListNft({ payload }) {
  const { error, response } = yield call(postCall, { path: "/unListItem", payload });
  if (error) {
    yield put(setLoader({ status: false }));
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put(setUserNft(response["data"]["body"]));
    yield put(setLoader({ status: false }));
    EventBus.publish("success", response["data"]["message"]);
  }
}

// transfer nft
export function* transferNft({ payload }) {
  const { error, response } = yield call(postCall, { path: "/sellAsset", payload });
  if (error) {
    yield put(setLoader({ status: false }));
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put(setAllNfts(response["data"]["body"]));
    yield put(setLoader({ status: false }));
    EventBus.publish("success", response["data"]["message"]);
  }
}


/*========== ACTIVITY FUNCTIONS =============*/
export function* getAllNfts({ payload }) {
  const { error, response } = yield call(getCall, `/getAllAssets`);
  if (error) {
    yield put(setAllNfts([]));
    yield put(setLoader({ status: false }));
    // EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put(setAllNfts(response["data"]["body"]));
    yield put(setLoader({ status: false }));
    // EventBus.publish("success", response["data"]["message"]);
  }
}

/*========== CREATE STORE FUNCTIONS =============*/
export function* createStore({ payload }) {
  const { error, response } = yield call(postCall, { path: "/createStore", payload });
  if (error) {
    yield put(setStore([]));
    yield put(setLoader({ status: false }));
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put(setStore(response["data"]["body"]));
    yield put(setLoader({ status: false }));
    EventBus.publish("success", response["data"]["message"]);
  }
}

export function* getStore({ payload }) {
  const { error, response } = yield call(getCall, `/getStore`);
  if (error) {
    yield put(setStore([]));
    yield put(setLoader({ status: false }));
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put(setStore(response["data"]["body"]));
    yield put(setLoader({ status: false }));
    EventBus.publish("success", response["data"]["message"]);
  }
}

export function* getCollections({ payload }) {
  const { error, response } = yield call(getCall, `/getAllCollectionWithStore/${payload['_id']}`);
  if (error) {
    yield put(setCollections(error["response"]["data"]["body"]["collections"]));
    yield put(setSingleStore(error["response"]["data"]["body"]["store"]));
    yield put(setLoader({ status: false }));
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put(setCollections(response["data"]["body"]["collections"]));
    yield put(setSingleStore(response["data"]["body"]["store"]));
    yield put(setLoader({ status: false }));
    EventBus.publish("success", response["data"]["message"]);
  }
}


export function* createCollection({ payload }) {
  const { error, response } = yield call(postCall, { path: "/createCollection", payload });
  if (error) {
    yield put(setCollections([]));
    yield put(setLoader({ status: false }));
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put(setCollections(response["data"]["body"]));
    yield put(setLoader({ status: false }));
    EventBus.publish("success", response["data"]["message"]);
  }
}

/* =================================== DAO FUNCTIONS =================================== */

export function* getProposals({ payload }) {
  const { error, response } = yield call(postCall, { path: "/dao/getProposals", payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put(setProposals(response['data']['body']));
  }
};

export function* addProposal({ payload }) {
  const { error, response } = yield call(postCall, { path: "/dao/addProposal", payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) { };
  // else if (response) EventBus.publish("success", response['data']['message']);
};

export function* sendEmailDao({ payload }) {
  const { error, response } = yield call(postCall, { path: "/dao/sendEmail", payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) EventBus.publish("success", response['data']['message']);
};

/*========== Get Single Collection =============*/
export function* getSingleCollection({ payload }) {
  const { error, response } = yield call(getCall, `/getSingleCollection/${payload['_id']}`);
  if (error) {
    yield put(setLoader({ status: false }));
    yield put(setSingleCollection({}));
  } else if (response) {
    yield put(setLoader({ status: false }));
    yield put(setSingleCollection(response["data"]["body"]));
  }
}

/*========== Mint NFT FUNCTIONS =============*/
function* mintCollection({ payload }) {
  const { error, response } = yield call(postCall, { path: '/mintCollection', payload });
  if (error) {
    yield put(setLoader({ status: false }));
    EventBus.publish('error', error['response']['data']['message']);
  }
  else if (response) {
    yield put(setLoader({ status: false }));
    EventBus.publish('success', response['data']['message']);
  }
};

/*========== KYC FUNCTIONS =============*/

export function* getKycStatus({ payload }) {
  const { error, response } = yield call(postCall, { path: '/users/getKycStatus', payload });
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(setKyc(response['data']['body']));
    yield put(setKycStatus(response['data']['body'][0]['kycStatus']));
  }
};

export function* kycGStatus({ payload }) {
  const { error, response } = yield call(postCall, { path: '/users/kycGStatus', payload });
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(setKyc(response['data']['body']));
    yield put(setGKycStatus(response['data']['body'][0]['status']));
  }
};

export function* kycSubmit({ payload }) {
  let userRole = localStorage.getItem("userRole");
  payload['role'] = userRole;
  const { error, response } = yield call(postCall, { path: '/users/kycSubmit', payload });
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
  } else if (response) {
    yield put(kycResponse(response['data']['body']));
  }
};

export function* getXdcPrice() {
  const { error, response } = yield call(getCall, '/users/getXdcPrice');
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
  } else if (response) {
    yield put(setXdcPrice(response['data']['body'][0]['price']));
  }
};

function* actionWatcher() {
  yield takeEvery('LOGIN', login);
  yield takeEvery('GET_NONCE', getNonce);
  yield takeEvery('CREATE_NFT', createNFT);
  yield takeEvery('MINT_NFT_721', mintNFT721);
  yield takeEvery('GET_USER_NFTS', getUserNft);
  yield takeEvery('GET_SINGLE_NFT', getSingleNftDetails);
  yield takeEvery('LIST_NFT', listNft);
  yield takeEvery('UNLIST_NFT', unListNft);
  yield takeEvery('TRANSFER_NFT', transferNft);
  yield takeEvery('GET_ALL_NFTS', getAllNfts);
  yield takeEvery('MINT_COLLECTION', mintCollection);
  yield takeEvery('GET_SINGLE_COLLECTION', getSingleCollection);
  // createStore
  yield takeEvery('CREATE_STORE', createStore);
  yield takeEvery('GET_STORE', getStore);
  yield takeEvery('GET_COLLECTIONS', getCollections);
  yield takeEvery('CREATE_COLLECTION', createCollection);
  // DAO
  yield takeEvery('GET_PROPOSALS', getProposals);
  yield takeEvery('ADD_PROPOSAL', addProposal);
  yield takeEvery('SEND_EMAIL_DAO', sendEmailDao);
  // KYC
  yield takeEvery('GET_KYC_STATUS', getKycStatus);
  yield takeEvery('KYC_G_STATUS', kycGStatus);
  yield takeEvery('KYC_SUBMIT', kycSubmit);
  yield takeEvery('GET_XDC_PRICE', getXdcPrice);
};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};
