/*========== LOGIN ACTIONS ============= */

export const login = (data) => ({
  type: 'LOGIN',
  payload: data,
});

export const setLogin = (data) => ({
  type: 'SET_LOGIN',
  payload: data,
});

export const getNonce = (data) => ({
  type: 'GET_NONCE',
  payload: data,
});

export const setNonce = (data) => ({
  type: 'SET_NONCE',
  payload: data,
});

export const logout = () => ({
  type: 'LOGOUT'
});

export const setAddress = (data) => ({
  type: 'SET_ADDRESS',
  payload: data
});

export const setNetwork = (data) => ({
  type: 'SET_NETWORK',
  payload: data
})

export const setChangeNetwork = (data) => ({
  type: 'SET_CHANGE_NETWORK',
  payload: data
})

/*========== PAGE LOADER ACTIONS ============= */

export const setLoader = (data) => ({
  type: 'SET_LOADER',
  payload: data,
});

/*========== CREATE NFT ACTIONS ============= */

export const createNFT = (data) => ({
  type: 'CREATE_NFT',
  payload: data,
});

export const getSingleNft = (data) => ({
  type: 'GET_SINGLE_NFT',
  payload: data,
});

export const setSingleNft = (data) => ({
  type: 'SET_SINGLE_NFT',
  payload: data,
});

export const mintNFT721 = (data) => ({
  type: 'MINT_NFT_721',
  payload: data,
});

export const listNft = (data) => ({
  type: 'LIST_NFT',
  payload: data,
});

export const unListNft = (data) => ({
  type: 'UNLIST_NFT',
  payload: data,
});

export const transferNft = (data) => ({
  type: 'TRANSFER_NFT',
  payload: data,
});

export const getAllNfts = (data) => ({
  type: 'GET_ALL_NFTS',
  payload: data,
});

export const setAllNfts = (data) => ({
  type: 'SET_ALL_NFTS',
  payload: data,
});

/*========== CREATE Collection ACTIONS ============= */
export const getUserNft = (data) => ({
  type: 'GET_USER_NFTS',
  payload: data,
});

export const setUserNft = (data) => ({
  type: 'SET_USER_NFTS',
  payload: data,
});


/*========== CREATE STORE ============= */
export const createStore = (data) => ({
  type: 'CREATE_STORE',
  payload: data,
});

export const setStore = (data) => ({
  type: 'SET_STORE',
  payload: data,
});

export const getStore = (data) => ({
  type: 'GET_STORE',
  payload: data,
});

export const setSingleStore = (data) => ({
  type: 'SET_SINGLE_STORE',
  payload: data,
});

export const createCollection = (data) => ({
  type: 'CREATE_COLLECTION',
  payload: data,
});

export const getCollections = (data) => ({
  type: 'GET_COLLECTIONS',
  payload: data,
});

export const setCollections = (data) => ({
  type: 'SET_COLLECTIONS',
  payload: data,
});

export const setSingleCollection = (data) => ({
  type: 'SET_SINGLE_COLLECTIONS',
  payload: data,
});

export const getSingleCollection = (data) => ({
  type: 'GET_SINGLE_COLLECTION',
  payload: data,
});

export const mintCollection = (data) => ({
  type: 'MINT_COLLECTION',
  payload: data,
});

/*========== DAO STORE ============= */

export const getProposals = (data) => ({
  type: 'GET_PROPOSALS',
  payload: data,
});

export const setProposals = (data) => ({
  type: 'SET_PROPOSALS',
  payload: data,
});

export const addProposal = (data) => ({
  type: 'ADD_PROPOSAL',
  payload: data,
});

export const sendEmailDao = (data) => ({
  type: 'SEND_EMAIL_DAO',
  payload: data,
});

/*========== KYC ACTIONS ============= */

export const getKycStatus = (data) => ({
  type: 'GET_KYC_STATUS',
  payload: data
});

export const setKycStatus = (data) => ({
  type: 'SET_KYC_STATUS',
  payload: data
});

export const getXdcPrice = () => ({
  type: 'GET_XDC_PRICE',
});

export const setXdcPrice = (data) => ({
  type: 'SET_XDC_PRICE',
  payload: data
});

export const kycGStatus = (data) => ({
  type: 'KYC_G_STATUS',
  payload: data
});

export const setGKycStatus = (data) => ({
  type: 'SET_G_KYC_STATUS',
  payload: data
});

export const setKyc = (data) => ({
  type: 'SET_KYC',
  payload: data
});

export const kycResponse = (data) => ({
  type: 'KYC_RESPONSE',
  payload: data
});

export const kycSubmit = (data) => ({
  type: 'KYC_SUBMIT',
  payload: data
});

export const setRole = (data) => ({
  type: 'SET_ROLE',
  payload: data
});