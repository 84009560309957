const { web3 } = require('../web3');
const { env } = require('../config');

let DAODATA = require(`./${env}/MDAO.json`);
let ERC721 = require(`./${env}/ERC721.json`);
let LandDATA = require(`./${env}/LandNFT.json`);
let VendingMachineDATA = require(`./${env}/VendingMachine.json`);
let BusinessDATA = require(`./${env}/BusinessNFT.json`);
let CitizenDATA = require(`./${env}/CitizenNFT.json`);
let XVRDATA = require(`./${env}/XVRToken.json`);
let BusinessSTAKEDATA = require(`./${env}/BusinessStake.json`);
let CitizenSTAKEDATA = require(`./${env}/CitizenStake.json`);
let GOVERNORDATA = require(`./${env}/GovernorNFT.json`);
let DeployERC721 = require(`./${env}/DeployERC721.json`);
let Marketplace = require(`./${env}/marketplaceContract.json`);
let GOVERNORSTAKEDATA = require(`./${env}/GovernorStake.json`);

let ERC721ABI = "", MarketplaceAddress = "", MarketplaceAbi = "", MarketplaceToken = "", ERC721ADDRESS = "", ERC721Token = "", DEPLOYERC721BYTECODE = "", DEPLOYERC721ABI = "",
  VendingMachineABI = "", VendingMachineAddress = "", VendingMachineToken = "", XVRABI = "", XVRAddress = "", XVRToken = "", GovernorNFTABI = "", GovernorNFTAddress = "", GovernorNFT = "",
  BusinessNFTABI = "", BusinessNFTAddress = "", BusinessNFT = "", CitizenNFTABI = "", CitizenNFTAddress = "", CitizenNFT = "", GovernorStakeABI = "", GovernorStakeAddress = "", GovernorStake = "",
  BusinessStakeABI = "", BusinessStakeAddress = "", BusinessStake = "", CitizenStakeABI = "", CitizenStakeAddress = "", CitizenStake = "", DAOABI = "", DAOAddress = "", DAO = "",
  LandNFTABI = "", LandNFTAddress = "", LandNFT = "";

const makeTokens = async () => {
  return new Promise(async (resolve, reject) => {
    let chain = await web3.eth.getChainId();
    chain = web3.utils.hexToNumber(chain);
    chain = Number(chain);
    if (chain == 50 || chain == 51) {

      /********* ERC721 *********/
      ERC721ABI = ERC721['abi'];
      ERC721ADDRESS = ERC721['address'];
      ERC721Token = new web3.eth.Contract(ERC721ABI, ERC721ADDRESS);

      /********* Marketplace ********/
      MarketplaceAddress = Marketplace['address']
      MarketplaceAbi = Marketplace['abi']
      MarketplaceToken = new web3.eth.Contract(MarketplaceAbi, MarketplaceAddress);

      /********* DEPLOY ERC721 ********/
      DEPLOYERC721BYTECODE = DeployERC721['bytecode']
      DEPLOYERC721ABI = DeployERC721['abi']

      /********* VendingMachine Token *********/
      VendingMachineAddress = VendingMachineDATA['address']
      VendingMachineABI = VendingMachineDATA['abi'];
      VendingMachineToken = new web3.eth.Contract(VendingMachineABI, VendingMachineAddress);

      /********* XVR Token *********/
      XVRAddress = XVRDATA['address']
      XVRABI = XVRDATA['abi'];
      XVRToken = new web3.eth.Contract(XVRABI, XVRAddress);

      /********* Governor NFT *********/
      GovernorNFTAddress = GOVERNORDATA['address']
      GovernorNFTABI = GOVERNORDATA['abi'];
      GovernorNFT = new web3.eth.Contract(GovernorNFTABI, GovernorNFTAddress);

      /********* Business NFT *********/
      BusinessNFTAddress = BusinessDATA['address']
      BusinessNFTABI = BusinessDATA['abi'];
      BusinessNFT = new web3.eth.Contract(BusinessNFTABI, BusinessNFTAddress);

      /********* Citizen NFT *********/
      CitizenNFTAddress = CitizenDATA['address']
      CitizenNFTABI = CitizenDATA['abi'];
      CitizenNFT = new web3.eth.Contract(CitizenNFTABI, CitizenNFTAddress);

      /********* Land NFT *********/
      LandNFTAddress = LandDATA['address']
      LandNFTABI = LandDATA['abi'];
      LandNFT = new web3.eth.Contract(LandNFTABI, LandNFTAddress);

      /********* Governor Stake *********/
      GovernorStakeAddress = GOVERNORSTAKEDATA['address']
      GovernorStakeABI = GOVERNORSTAKEDATA['abi'];
      GovernorStake = new web3.eth.Contract(GovernorStakeABI, GovernorStakeAddress);

      /********* Business Stake *********/
      BusinessStakeAddress = BusinessSTAKEDATA['address']
      BusinessStakeABI = BusinessSTAKEDATA['abi'];
      BusinessStake = new web3.eth.Contract(BusinessStakeABI, BusinessStakeAddress);

      /********* Citizen Stake *********/
      CitizenStakeAddress = CitizenSTAKEDATA['address']
      CitizenStakeABI = CitizenSTAKEDATA['abi'];
      CitizenStake = new web3.eth.Contract(CitizenStakeABI, CitizenStakeAddress);

      /********* DAO *********/
      DAOAddress = DAODATA['address']
      DAOABI = DAODATA['abi'];
      DAO = new web3.eth.Contract(DAOABI, DAOAddress);

      return resolve({
        ERC721Token, ERC721ADDRESS, MarketplaceToken, MarketplaceAddress, DEPLOYERC721BYTECODE, DEPLOYERC721ABI, CitizenStakeABI, CitizenStake, DAOABI, DAOAddress, DAO,
        VendingMachineAddress, VendingMachineABI, VendingMachineToken, XVRAddress, XVRABI, XVRToken, GovernorNFTAddress, GovernorNFTABI, GovernorNFT, BusinessNFTAddress, BusinessNFTABI,
        BusinessNFT, CitizenNFTAddress, CitizenNFTABI, CitizenNFT, GovernorStakeAddress, GovernorStakeABI, GovernorStake, BusinessStakeAddress, BusinessStakeABI, BusinessStake, CitizenStakeAddress,
        LandNFTAddress, LandNFTABI, LandNFT
      })
    }
  })
}

export {
  web3,
  makeTokens,
};
