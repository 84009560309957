import { connect } from "react-redux";
import { Veriff } from '@veriff/js-sdk';
import { React, useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link, NavLink, useNavigate } from "react-router-dom";

import { kycSubmit, setRole } from "../../store/actions/Auth";

const logo = "/images/main-logo.png";
const arrow = "/images/arrow.png";
const eye = "/images/eye.png";

const KYC = (props) => {

    const navigate = useNavigate();
    let [key, setKey] = useState('home');

    useEffect(() => {
        veriffStart();
    }, []);

    const veriffStart = async () => {
        // console.log("********************* 1");
        const waitFor = (delay) =>
            new Promise((resolve) => setTimeout(resolve, delay));
        const veriff = Veriff({
            host: 'https://stationapi.veriff.com',
            apiKey: '325f3df4-a4d9-4e86-a8ca-9460a19b62e3',
            parentId: 'veriff-root',
            onSession: async function (err, response) {
                await waitFor(1500);
                // console.log("********************* 1.1");
                // console.log("********************* err", err);
                // console.log("********************* response", response);
                let id = response.verification.id;
                let vendorData = response.verification.vendorData;
                let url = response.verification.url;
                let status = response.verification.status;
                let session = response.verification.sessionToken;
                // console.log("*** id: ", id);
                // console.log("*** vendorData: ", vendorData);
                // console.log("*** url: ", url);
                // console.log("*** status: ", status);
                // console.log("*** session: ", session);
                props.kycSubmit({ id, vendorData, url, status, session });
                // axios.post(`https://www.server.blockfund.world/api/v1/submitVeriff`, { id, vendorData, url, status, session });
                await waitFor(1500);
                // console.log("********************* response.verification.url: ", response);
                // window.veriffSDK.createVeriffFrame({ url: response.verification.url });
                window.location.href = response.verification.url;
            }
        });
        // console.log("********************* 2");
        veriff.mount({
            formLabel: {
                givenName: 'First Name',
                lastName: 'Last Name',
                vendorData: 'Metamask Wallet Address'
            },
        });
        // console.log("********************* 3");
    }

    let { kycGStatus } = props;

    // console.log("****** kyc", kycGStatus);

    return (
        <>

            <div className="account kyc">
                <div class="left">
                    <div className='inner'>
                        <Link className="logo" to="/">
                            <img src={logo} alt="logo" />
                        </Link>

                        <div className="head">
                            <h1>KYC</h1>

                            <p>Verify your Identity to access the features of Xverse.</p>
                        </div>
                        {
                            kycGStatus == "" ?
                                <>
                                    <div id='veriff-root' className="edit-add-body col-12"></div>
                                </>
                                :
                                <p>Verification Already Submitted</p>
                        }
                    </div>
                </div>

                <div className="right">
                    <span>KYC</span>
                </div>
            </div>

        </>
    );
};

const mapDispatchToProps = {
    kycSubmit, setRole
};

const mapStateToProps = ({ Auth }) => {
    let { kycStatus, kycValue, kycSubmitted, kycGStatus } = Auth;
    return { kycStatus, kycValue, kycSubmitted, kycGStatus };
};
export default connect(mapStateToProps, mapDispatchToProps)(KYC);