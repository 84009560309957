import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { React, useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";

import Header from '../TopBar';
import MainLinks from '../MainLinks';
import { web3 } from "../../store/web3";
import { setLoader } from "../../store/actions/Auth";
import { makeTokens } from "../../store/contract/index";

const logo = "/images/main-logo.png";
const sidebararrow = "/images/sidebar-arrow.png";

const NFTMinting = (props) => {
    const onOpen = () => SetOPen(true);
    const [Open, SetOPen] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const sidebarArrow = () => {
        setIsActive(!isActive);
    };

    const navigate = useNavigate();
    let [nftType, setNftType] = useState('GVN');
    let [totalNFT, setTotalNFT] = useState('');
    let [mintedNFT, setMintedNFT] = useState('');
    let [priceNFT, setPriceNFT] = useState('');

    useEffect(() => {
        loadData(nftType);
    }, [nftType])

    async function loadData(nftType) {
        const { GovernorNFT, BusinessNFT, CitizenNFT } = await makeTokens();
        if (nftType == "GVN") {
            let tSupply = await GovernorNFT.methods.maxSupply().call();
            setTotalNFT(parseInt(tSupply));
            let tMinted = await GovernorNFT.methods.totalSupply(1).call();
            setMintedNFT(parseInt(tMinted));
            let tPrice = await GovernorNFT.methods.price().call();
            tPrice = web3.utils.fromWei(tPrice.toString(), "ether");
            setPriceNFT(parseInt(tPrice));
        } else if (nftType == "Citizen") {
            let tMinted = await CitizenNFT.methods.totalSupply(1).call();
            setMintedNFT(parseInt(tMinted));
            let tPrice = await CitizenNFT.methods.price().call();
            tPrice = web3.utils.fromWei(tPrice.toString(), "ether");
            setPriceNFT(parseInt(tPrice));
        } else if (nftType == "Business") {
            let tMinted = await BusinessNFT.methods.totalSupply(1).call();
            setMintedNFT(parseInt(tMinted));
            let tPrice = await BusinessNFT.methods.price().call();
            tPrice = web3.utils.fromWei(tPrice.toString(), "ether");
            setPriceNFT(parseInt(tPrice));
        }
    }

    async function mintNFT(e) {
        try {
            e.preventDefault();

            const { XVRToken, XVRAddress, GovernorNFTAddress, GovernorNFT, BusinessNFTAddress, BusinessNFT, CitizenNFTAddress, CitizenNFT } = await makeTokens();

            const waitFor = (delay) =>
                new Promise((resolve) => setTimeout(resolve, delay));

            let { publicAddress } = props;

            if (publicAddress == null || publicAddress == undefined) {
                EventBus.publish("error", `Please connect your wallet!`);
                return;
            }

            let deployer = (await web3.currentProvider.enable())[0];

            const balanceWei = await web3.eth.getBalance(deployer);
            const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

            priceNFT = web3.utils.toWei(priceNFT.toString(), "ether");




            if (nftType == "Business") {

                let userBalance = await XVRToken.methods.balanceOf(deployer).call();
                if (parseInt(userBalance) < parseInt(priceNFT)) return EventBus.publish("error", `Insufficient XVR Balance!`);

                props.setLoader({
                    message: "Approval in Progress...",
                    status: true,
                });

                await XVRToken.methods.approve(BusinessNFTAddress, priceNFT.toString()).send({
                    from: deployer,
                });

                props.setLoader({
                    message: "Approval complete...",
                    status: true,
                });

            }


            props.setLoader({
                message: "Minting in Progress...",
                status: true,
            });

            let from = publicAddress;
            let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
            if (output == "xdc") {
                from = "0x" + publicAddress.substring(3);
            } else {
                from = publicAddress;
            }

            if (nftType == "GVN") {
                await web3.eth
                    .sendTransaction({
                        from: deployer,
                        value: priceNFT.toString(),
                        to: GovernorNFTAddress,
                        gas: 5000000,
                        data: GovernorNFT.methods
                            .mint(1, from, 1)
                            .encodeABI(),
                    })
                    .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
                    .on('receipt', async receipt => {
                        props.setLoader({ status: false });
                        loadData("GVN");
                        EventBus.publish("success", `GVN NFT Minted Successfully!`);
                    });
            } else if (nftType == "Citizen") {
                await web3.eth
                    .sendTransaction({
                        from: deployer,
                        value: priceNFT.toString(),
                        to: CitizenNFTAddress,
                        gas: 5000000,
                        data: CitizenNFT.methods
                            .mint(1, from, 1)
                            .encodeABI(),
                    })
                    .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
                    .on('receipt', async receipt => {
                        props.setLoader({ status: false });
                        loadData("Citizen");
                        EventBus.publish("success", `Citizen NFT Minted Successfully!`);
                    });
            } else if (nftType == "Business") {
                await web3.eth
                    .sendTransaction({
                        from: deployer,
                        to: XVRAddress,
                        gas: 5000000,
                        data: XVRToken.methods
                            .transfer(BusinessNFTAddress, priceNFT.toString())
                            .encodeABI(),
                    })
                    .on('transactionHash', hash => console.log(`************** deploy dao contract hash = ${hash}`))
                    .on('receipt', async receipt => {
                        await web3.eth
                            .sendTransaction({
                                from: deployer,
                                value: 0,
                                to: BusinessNFTAddress,
                                gas: 5000000,
                                data: BusinessNFT.methods
                                    .mint(1, from, 1, priceNFT.toString())
                                    .encodeABI(),
                            })
                            .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
                            .on('receipt', async receipt => {
                                props.setLoader({ status: false });
                                loadData("Citizen");
                                EventBus.publish("success", `Business NFT Minted Successfully!`);
                            });
                    });
            }
        } catch (e) {
            console.log(e);
            props.setLoader({
                message: "Mint Not Completed...",
                status: false,
            });
            EventBus.publish("error", `Mint Failed`);
        }
    };

    return (
        <>
            <div className="account sidebar">
                <div className={isActive ? 'left open' : 'left'}>
                    <div className='inner'>
                        <Link className="logo" to="/">
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>

                    <MainLinks />
                </div>

                <div className={isActive ? 'bg-open active' : 'bg-open'}>
                </div>

                <button className={isActive ? 'sidebar-arrow active' : 'sidebar-arrow'} onClick={sidebarArrow}>
                    <img src={sidebararrow} />
                </button>

                <div className="right">
                    <Header />

                    <div className='container'>
                        <div className="form-wrap minting">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="governor-nft-tab" data-bs-toggle="tab" data-bs-target="#governor-nft" type="button" role="tab" aria-controls="governor-nft" aria-selected="true" onClick={() => setNftType('GVN')}>Governor</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="business-tab" data-bs-toggle="tab" data-bs-target="#business" type="button" role="tab" aria-controls="business" aria-selected="false" onClick={() => setNftType('Business')}>Business</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="citizen-tab" data-bs-toggle="tab" data-bs-target="#citizen" type="button" role="tab" aria-controls="citizen" aria-selected="false" onClick={() => setNftType('Citizen')}>Citizen</button>
                                </li>
                            </ul>

                            <div className="head">
                                <p>Awaken your inner creator. Our NFT Minting portal isn't just a tool; it's a space where your imagination takes form. Craft unique digital masterpieces and see them flourish in the vast expanse of the decentralized Xverse.</p>
                            </div>

                            <Form onClick={mintNFT}>

                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="governor-nft" role="tabpanel" aria-labelledby="governor-nft-tab">
                                        <Form.Group className="mb-3 form-group" controlId="formBasictotalNFTs">
                                            <Form.Label>Total number of NFTs</Form.Label>

                                            <Form.Control type="text" readOnly disabled value={totalNFT} />
                                        </Form.Group>

                                        <Form.Group className="mb-3 form-group" controlId="formBasictotalMintedNFTs">
                                            <Form.Label>Total Minted NFTs</Form.Label>

                                            <Form.Control type="text" readOnly disabled value={mintedNFT} />
                                        </Form.Group>

                                        <Form.Group className="mb-3 form-group" controlId="formBasicpriceperNFT">
                                            <Form.Label>Price per NFT</Form.Label>

                                            <Form.Control type="text" readOnly disabled value={`${priceNFT} XDC`} />
                                        </Form.Group>

                                        <Form.Group className="mb-3 form-group" controlId="formBasicconnectedaddress">
                                            <Form.Label>Connected address</Form.Label>

                                            <Form.Control type="text" readOnly disabled value={props.publicAddress} />
                                        </Form.Group>
                                    </div>

                                    <div class="tab-pane fade" id="business" role="tabpanel" aria-labelledby="business-tab">
                                        <Form.Group className="mb-3 form-group" controlId="formBasictotalNFTs">
                                            <Form.Label>Total number of NFTs</Form.Label>

                                            <Form.Control type="text" readOnly disabled value={totalNFT} />
                                        </Form.Group>

                                        <Form.Group className="mb-3 form-group" controlId="formBasictotalMintedNFTs">
                                            <Form.Label>Total Minted NFTs</Form.Label>

                                            <Form.Control type="text" readOnly disabled value={mintedNFT} />
                                        </Form.Group>

                                        <Form.Group className="mb-3 form-group" controlId="formBasicpriceperNFT">
                                            <Form.Label>Price per NFT</Form.Label>

                                            <Form.Control type="text" readOnly disabled value={`${priceNFT} XVR`} />
                                        </Form.Group>

                                        <Form.Group className="mb-3 form-group" controlId="formBasicconnectedaddress">
                                            <Form.Label>Connected address</Form.Label>

                                            <Form.Control type="text" readOnly disabled value={props.publicAddress} />
                                        </Form.Group>
                                    </div>

                                    <div class="tab-pane fade" id="citizen" role="tabpanel" aria-labelledby="citizen-tab">
                                        <Form.Group className="mb-3 form-group" controlId="formBasictotalNFTs">
                                            <Form.Label>Total number of NFTs</Form.Label>

                                            <Form.Control type="text" readOnly disabled value={totalNFT} />
                                        </Form.Group>

                                        <Form.Group className="mb-3 form-group" controlId="formBasictotalMintedNFTs">
                                            <Form.Label>Total Minted NFTs</Form.Label>

                                            <Form.Control type="text" readOnly disabled value={mintedNFT} />
                                        </Form.Group>

                                        <Form.Group className="mb-3 form-group" controlId="formBasicpriceperNFT">
                                            <Form.Label>Price per NFT</Form.Label>

                                            <Form.Control type="text" readOnly disabled value={`${priceNFT} XDC`} />
                                        </Form.Group>

                                        <Form.Group className="mb-3 form-group" controlId="formBasicconnectedaddress">
                                            <Form.Label>Connected address</Form.Label>

                                            <Form.Control type="text" readOnly disabled value={props.publicAddress} />
                                        </Form.Group>
                                    </div>
                                </div>

                                <Button className="common-btn" type="submit">
                                    Mint now
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapDispatchToProps = {
    setLoader
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress } = Auth;
    return { publicAddress }
};

export default connect(mapStateToProps, mapDispatchToProps)(NFTMinting);