import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { React, useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";

import Header from '../TopBar';
import MainLinks from '../MainLinks';
import { web3 } from "../../store/web3";
import { toFixed } from "../../store/numberFormat";
import { setLoader } from "../../store/actions/Auth";
import { makeTokens } from "../../store/contract/index";

const logo = "/images/main-logo.png";
const sidebararrow = "/images/sidebar-arrow.png";

const NFTMinting = (props) => {

    let { nftId } = useParams();

    const onOpen = () => SetOPen(true);
    const [Open, SetOPen] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [price, setPrice] = useState(0);
    const [bought, setBought] = useState(false);
    const [owner, setOwner] = useState("");

    const sidebarArrow = () => {
        setIsActive(!isActive);
    };

    useEffect(() => {
        if (props.publicAddress) {
            loadData();
        }
    }, [props.publicAddress]);

    async function loadData() {
        const { LandNFT } = await makeTokens();
        let minted = await LandNFT.methods.checkMinted(nftId).call();
        if (minted == true) {
            let owner = await LandNFT.methods.ownerOf(nftId).call();
            setOwner(owner);
        }
        setBought(minted);
        let price = await LandNFT.methods.price().call();
        price = web3.utils.fromWei(price, 'ether');
        setPrice(toFixed(parseFloat(price), "price"));
    }

    async function mintNFT(e) {
        try {
            e.preventDefault();
            
            const { LandNFTAddress, LandNFT } = await makeTokens();
            
            let { publicAddress } = props;
            
            if (parseInt(nftId) <= 0) {
                EventBus.publish("error", `Invalid Land ID!`);
                return;
            }
            
            if (parseInt(nftId) == NaN && parseInt(nftId) == undefined) {
                EventBus.publish("error", `Invalid Land ID!`);
                return;
            }
            
            let minted = await LandNFT.methods.checkMinted(nftId).call();
            
            if (minted == true) {
                EventBus.publish("error", `Land already purchased!`);
                return;
            }

            let price = await LandNFT.methods.price().call();
            
            if (publicAddress == null || publicAddress == undefined) {
                EventBus.publish("error", `Please connect your wallet!`);
                return;
            }

            let deployer = (await web3.currentProvider.enable())[0];
            
            const balanceWei = await web3.eth.getBalance(deployer);
            const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);
            if (balanceWei < price) return EventBus.publish("error", `Insufficient balance to purchase land, required: ${web3.utils.fromWei(price, 'ether')} XDC`);
            
            props.setLoader({
                message: "Purchase in Progress...",
                status: true,
            });
            
            await web3.eth
                .sendTransaction({
                    from: deployer,
                    value: price.toString(),
                    to: LandNFTAddress,
                    gas: 5000000,
                    data: LandNFT.methods
                        .mint(deployer, parseInt(nftId))
                        .encodeABI(),
                })
                .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
                .on('receipt', async receipt => {
                    props.setLoader({ status: false });
                    setBought(true);
                    setOwner(deployer);
                    EventBus.publish("success", `Land Purchased Successfully!`);
                });
        } catch (e) {
            console.log(e);
            props.setLoader({
                message: "Purchase Not Completed...",
                status: false,
            });
            EventBus.publish("error", `Purchase Failed`);
        }
    };

    const handleCopyText = () => {
        navigator.clipboard.writeText(owner)
            .then(() => EventBus.publish("success", "Owner Address Copied"))
            .catch((error) => console.log('Copy failed:', error));
    };


    return (
        <>
            <div className="account sidebar">
                <div className={isActive ? 'left open' : 'left'}>
                    <div className='inner'>
                        <Link className="logo" to="/">
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>

                    <MainLinks />
                </div>

                <div className={isActive ? 'bg-open active' : 'bg-open'}>
                </div>

                <button className={isActive ? 'sidebar-arrow active' : 'sidebar-arrow'} onClick={sidebarArrow}>
                    <img src={sidebararrow} />
                </button>

                <div className="right">
                    <Header />

                    <div className='container'>
                        <div className="form-wrap minting">
                            {
                                !bought ?
                                    <>
                                        <Form onClick={mintNFT}>
                                            <h2 className="mb-4">Minting</h2>
                                            <p className="mb-4">Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt.</p>
                                            <div class="tab-content" id="myTabContent">
                                                <div class="tab-pane fade show active" id="governor-nft" role="tabpanel" aria-labelledby="governor-nft-tab">
                                                    <Form.Group className="mb-3 form-group" controlId="formBasicplotId">
                                                        <Form.Label>Plot ID</Form.Label>

                                                        <Form.Control type="text" readOnly disabled value={nftId} />
                                                    </Form.Group>

                                                    <Form.Group className="mb-3 form-group" controlId="formBasicplotPrice">
                                                        <Form.Label>Price of Plot</Form.Label>

                                                        <Form.Control type="text" readOnly disabled value={price} />
                                                    </Form.Group>

                                                    <Form.Group className="mb-3 form-group" controlId="formBasicconnectedaddress">
                                                        <Form.Label>Connected address</Form.Label>

                                                        <Form.Control type="text" readOnly disabled value={props.publicAddress && props.publicAddress.substring(0, 5) + '...' + props.publicAddress.substring(37, props.publicAddress.length)} />
                                                    </Form.Group>
                                                </div>
                                            </div>

                                            <Button className="common-btn" type="submit">
                                                Mint now
                                            </Button>
                                        </Form>
                                    </>
                                    :
                                    <>
                                        <h2 className="mb-4">Land already bought by:</h2>
                                        <p className="mb-4" onClick={() => handleCopyText()}>{owner && owner.substring(0, 5) + '...' + owner.substring(37, owner.length)}</p>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapDispatchToProps = {
    setLoader
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress } = Auth;
    return { publicAddress }
};

export default connect(mapStateToProps, mapDispatchToProps)(NFTMinting);