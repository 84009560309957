import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import EventBus from 'eventing-bus';
import {web3,makeTokens} from "../../store/contract";
import { setLoader, getUserNft, createNFT, listNft, unListNft } from '../../store/actions/Auth';
import { CreateIPFS, CreateIPFSMetadata} from "../../store/ipfs";
import { Button, Modal} from 'react-bootstrap';
import { FileUploader } from "react-drag-drop-files";
import Header from '../Header';
import Footer from '../Footer';
const alternate = "/images/alternate.jpg";
const zipFile = "/images/pngtree-zip.png";

const Listing = (props) => {
    const navigate = useNavigate();
    const fileTypes = ["ZIP"];
    const fileTypes3d = ["PNG","GIF","JPG","JPEG"];
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showModal, setShowModal] = useState(false);

    
    const [collections, setCollections] = useState([]);
    const [itemPerRow, setItemPerRow] = useState(10);
    const [next, setNext] = useState(itemPerRow);
    const [title, setTitle] = useState();
    const [price, setPrice] = useState();
    const [image, setImage] = useState();
    const [NftId, setNftId] = useState();
    const [tokenAddress, setTokenAddress] = useState();
    // const [file, setFile] = useState();

    const CloseModal = () => setShowModal(false);
    const ShowModal = (nftId,tokenAddress) => {
        setNftId(nftId);
        setTokenAddress(tokenAddress);
        setShowModal(true);
    }

    const [file, setFile] = useState(null);
    const [fileImage, setFileImage] = useState(null);
    const handleChange = (file) => {
        setFile(file);
    };

    const handleChangeImage = (file) => {
        setFileImage(file);
    };
    

    useEffect(() => {
        props.setLoader({status:true,message:"Load Listings..."})
        props.getUserNft({publicAddress: props.publicAddress});
    }, []);

    useEffect(()=>{
        if(props.userNfts?.length > 0) setCollections(props.userNfts);
    },[props.userNfts])

    const handleMoreImage = () => {
        setNext(next + itemPerRow);
    };

    const uploadImage = async (e) => {
        let files = e.target.files[0].type;
        if (files.split('/')[0] !== 'image') return EventBus.publish('error', `Please upload image only in jpeg, png, gif`);
        let reader = new FileReader();
        reader.onload = async (event) => {
            setImage(event.target.result);
        };
        reader.readAsDataURL(e.target.files[0]);
        setFile(e.target.files[0]);
    };

    const handleCollectionImageError = (idx) => {
        collections[idx]['profileImage'] = alternate;
        setCollections([...collections]);
    }

    const uploadAssets = async (e) => {
        try {
            e.preventDefault();
            if(!file) return EventBus.publish('error', "please upload asset");
            if(!fileImage) return EventBus.publish('error', "please upload image");
            if(!price) return EventBus.publish('error', "please set price");
            if(!title) return EventBus.publish('error', "please set title");
            const balanceWei = await web3.eth.getBalance(props.publicAddress);
            const balanceEth = web3.utils.fromWei(balanceWei, 'ether');
            if(Number(balanceEth) == 0) return EventBus.publish('error', "insufficient balance");
            props.setLoader({status:true, message:"Uploading asset..."});
            let imageUri = await CreateIPFS(file);
            props.setLoader({status:true, message:"Uploading image..."});
            let image3D = await CreateIPFS(fileImage);
            props.setLoader({status:true, message:"Uploading asset..."});
            let {ERC721Token,ERC721ADDRESS,MarketplaceToken,MarketplaceAddress} = await makeTokens();
            let wPrice = await web3.utils.toWei(price.toString(), 'ether');
            web3.eth.sendTransaction({
                from: props.publicAddress,
                to: ERC721ADDRESS,
                value: 0,
                data: ERC721Token.methods.createToken(imageUri,wPrice).encodeABI(),
              }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                .on('receipt', async receipt => {
                    let nftId =  await ERC721Token.methods.walletOfOwner(props.publicAddress).call();
                    nftId = nftId[nftId.length - 1];
                    props.setLoader({ message: 'Approve asset for listing...', status: true });
                    web3.eth.sendTransaction({
                        from: props.publicAddress,
                        to: ERC721ADDRESS,
                        value: 0,
                        data: ERC721Token.methods.approve(MarketplaceAddress,nftId).encodeABI()
                      }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                        .on('receipt', async receipt => {
                        props.setLoader({ message: 'List asset...', status: true });
                        web3.eth.sendTransaction({
                            from: props.publicAddress,
                            to: MarketplaceAddress,
                            value: 0,
                            data: MarketplaceToken.methods.createMarketItem(ERC721ADDRESS,nftId,wPrice).encodeABI(),
                          }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                            .on('receipt', async receipt => {
                            props.createNFT({publicAddress:props.publicAddress,nftId,imageUri,image3D,price,title,tokenAddress:ERC721ADDRESS});
                            handleClose()
                            CloseModal()
                            setPrice();
                        }).on('error', e => {
                            setPrice("");
                            console.log("**************** e",e)
                            props.setLoader({status:false});
                            EventBus.publish('error', `Unable to List Asset ${e}`);
                    });
                    }).on('error', e => {
                        setPrice("");
                        console.log("**************** e",e)
                        props.setLoader({status:false});
                        EventBus.publish('error', `Unable to List Asset ${e}`);
                });
                }).on('error', e => {
                  props.setLoader({status:false});
                  EventBus.publish('error', `Unable to Upload Asset ${e}`);
                });
           } catch (error) {
                console.log("*********** error",error)
                props.setLoader({status:false});
                return EventBus.publish('error', error.message);
           }
    }

    const listNft = async (e) =>{
        try {
            e.preventDefault();
            if(!price) return EventBus.publish('error',`Please set price`);
            if(!props.publicAddress) return EventBus.publish('error',`Please connect wallet`);
            let nftId = NftId;
            const balanceWei = await web3.eth.getBalance(props.publicAddress);
            const balanceEth = web3.utils.fromWei(balanceWei, 'ether');
            if(Number(balanceEth) == 0) return EventBus.publish('error', "insufficient balance");

            props.setLoader({status:true, message:"Approve for list asset..."});
            let {DEPLOYERC721ABI,MarketplaceToken,MarketplaceAddress} = await makeTokens();
            let ERC721Token = new web3.eth.Contract(DEPLOYERC721ABI,tokenAddress);
            let wPrice = await web3.utils.toWei(price.toString(), 'ether');

            web3.eth.sendTransaction({
                from: props.publicAddress,
                to: tokenAddress,
                value: 0,
                data: ERC721Token.methods.approve(MarketplaceAddress,nftId).encodeABI()
              }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                .on('receipt', async receipt => {
                props.setLoader({ message: 'List nft...', status: true });
                web3.eth.sendTransaction({
                    from: props.publicAddress,
                    to: MarketplaceAddress,
                    value: 0,
                    data: MarketplaceToken.methods.createMarketItem(tokenAddress,nftId,wPrice).encodeABI(),
                  }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                    .on('receipt', async receipt => {
                    props.listNft({publicAddress:props.publicAddress,nftId,price,tokenAddress:tokenAddress});
                    handleClose()
                    CloseModal()
                    setPrice();
                }).on('error', e => {
                    setPrice("");
                    console.log("**************** e",e)
                    props.setLoader({status:false});
                    EventBus.publish('error', `Unable to List asset ${e}`);
            });
            }).on('error', e => {
                setPrice("");
                console.log("**************** e",e)
                props.setLoader({status:false});
                EventBus.publish('error', `Unable to List asset ${e}`);
        });
        } catch (error) {
            console.log("********************** error",error);
            props.setLoader({status:false});
            return EventBus.publish('error', error.message);
        }
    }

    const unListNft = async (nftId) =>{
        try {
            if(!props.publicAddress) return EventBus.publish('error',`Please connect wallet`);
            const balanceWei = await web3.eth.getBalance(props.publicAddress);
            const balanceEth = web3.utils.fromWei(balanceWei, 'ether');
            if(Number(balanceEth) == 0) return EventBus.publish('error', "insufficient balance");
            props.setLoader({ message: 'Unlist asset...', status: true });
            let {MarketplaceToken,MarketplaceAddress} = await makeTokens();
                web3.eth.sendTransaction({
                    from: props.publicAddress,
                    to: MarketplaceAddress,
                    value: 0,
                    data: MarketplaceToken.methods.unListItems(tokenAddress,nftId).encodeABI(),
                  }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                    .on('receipt', async receipt => {
                    props.unListNft({nftId, tokenAddress:tokenAddress, publicAddress:props.publicAddress})
                    }).on('error', e => {
                      props.setLoader({status:false});
                      EventBus.publish('error', `Unable to List asset ${e}`);
                });
        } catch (error) {
            console.log("********************** error",error);
            props.setLoader({status:false});
            return EventBus.publish('error', error.message);
        }
    }

    if (!props.isLogin) return navigate("/");
    return(
        <>
        <Header />
        <section class="market">
        <div class="container">
            <div class="row title-row justify-content-between align-items-center">
                <div class="col-md-8 mb-md-0 mb-4">
                    <h1><span>Your Listings</span></h1>
                    <p>Let's explore our market of 3d assets and experience with Xverse.</p>
                </div>
                <div class="col-md-4 btns text-md-end">
                    <a class="add-btn" onClick={handleShow}>Add new asset</a>
                </div>
            </div>
            <div class="row detail-row">
                {collections?.length > 0 && collections.map((item, idx)=>
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="thumbnail">
                        {/* <img key={idx} src={zipFile} alt=""  onError={() => handleCollectionImageError(idx)}/> */}
                            <img key={idx} src={item['image3D'] ? item['image3D'] : alternate} alt=""  onError={() => handleCollectionImageError(idx)}/>
                        </div>
                        <h5>{item['title'] ? item['title'] : ""}</h5>
                        <ul>
                            <li class="price"> {item['price'] ? item['price'] : 0} XDC</li>
                            {item['isMarketItem']  == true ?
                            <li><a onClick={()=>unListNft(item['nftId'])}><img src="./images/trash.svg" alt="" /></a></li>
                            :
                            <li class="list-asset"><button onClick={()=>ShowModal(item['nftId'],item['tokenAddress'])}>List Asset</button></li>
                            }
                        </ul>
                    </div>
                </div>
                )}
            </div>
            {next < collections?.length && (
            <div class="load-more-btn">
                <a onClick={handleMoreImage}>Load More</a>
            </div>
            )}
        </div>
    </section>
    <Footer />

    {/* minting */}
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <h2>Add asset</h2>
            <p>List your asset in the market of Metavers</p>
            </Modal.Header>
            <Modal.Body>
            <div class="upload-card">
                <div className="drop_box">
                    <h4>Drag and drop ZIP file here</h4>
                    <FileUploader
                        multiple={false}
                        handleChange={handleChange}
                        name="file"
                        types={fileTypes}
                    />
                    <p>{file ? `File name: ${file.name}` : "no files uploaded yet"}</p>
                </div>
            </div>
            <div class="upload-card">
                <div className="drop_box">
                    <h4>Drag and drop 3D Image file here</h4>
                    <FileUploader
                        multiple={false}
                        handleChange={handleChangeImage}
                        name="files"
                        types={fileTypes3d}
                    />
                    <p>{fileImage ? `File name: ${fileImage.name}` : "no files uploaded yet"}</p>
                </div>
            </div>
            <form>
                <input type="text" placeholder='Add title' onChange={e=>setTitle(e.target.value)} maxLength={20}/>
                <input type="text" placeholder='Set price (ETH)' onChange={e=>setPrice(e.target.value)} />
                <button type='submit' onClick={uploadAssets}>Add asset</button>
            </form>
            </Modal.Body>
        </Modal>

        {/* list nft */}
        <Modal show={showModal} onHide={CloseModal}>
            <Modal.Header closeButton>
            <h2>List asset</h2>
            <p>List your asset in the market of Metavers</p>
            </Modal.Header>
            <Modal.Body>
            <form onSubmit={listNft}>
            <input type="text" placeholder='Set price (ETH)' onChange={e=>setPrice(e.target.value)} />
            <button type='submit' onClick={e=>listNft(e)}>List asset</button>
            </form>
            </Modal.Body>
        </Modal>
    </>
    );
};

const mapDispatchToProps = {getUserNft,createNFT,setLoader, listNft, unListNft};

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, userNfts, isLogin,} = Auth;
  return { publicAddress, userNfts, isLogin}
}

export default connect(mapStateToProps, mapDispatchToProps)(Listing);