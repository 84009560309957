import EventBus from 'eventing-bus';
import { connect } from "react-redux";
import React, { Component } from 'react'
import Loader from "./components/Loader";
import { createBrowserHistory } from "history";
import { tokenVerify } from "./store/jwtVerify";
import { ToastContainer, toast } from 'react-toastify';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { logout, setAddress, getXdcPrice } from './store/actions/Auth';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/pages/Home';
import Store from "./components/pages/Store";
import Listing from "./components/pages/Listing";
import Marketplace from "./components/pages/Marketplace";
import Collections from "./components/pages/Collections";
import CollectionDetail from "./components/pages/CollectionDetail";
import MintingCollection from "./components/pages/MintingCollection";
import Mapping from './components/pages/Mapping';
import Minting from './components/pages/Minting';

import KYC from "./components/pages/KYC";
import DAO from './components/pages/DAO';
import Login from "./components/pages/Login";
import Stake from './components/pages/Stake';
import SignUp from "./components/pages/SignUp";
import Dashboard from "./components/pages/Dashboard";
import NFTMinting from './components/pages/NFTMinting';

import Approve from './components/pages/Approve';
import Blacklist from './components/pages/Blacklist';
import ApproveKYC from './components/pages/ApproveKYC';
import AdminLogin from './components/pages/AdminLogin';
import About from './components/pages/About';
import Faq from './components/pages/Faq';
import Contact from './components/pages/Contact';

const hist = createBrowserHistory();

export class App extends Component {

  async componentDidMount() {
    this.props.getXdcPrice();
    EventBus.on('info', (e) => toast.info(e));
    EventBus.on('error', (e) => toast.error(e));
    EventBus.on('success', (e) => toast.success(e));
    EventBus.on("tokenExpired", () => this.props.logout());
    if (!tokenVerify()) EventBus.on("tokenExpired", () => this.props.logout());
  };

  render() {
    const isAuthenticated = localStorage.getItem("token");
    return (
      <div>
        <Loader />
        <ToastContainer closeOnClick position="bottom-left" theme="colored" />
        <BrowserRouter history={hist}>
          {/* <Header /> */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/listing" element={<Listing />} />
            <Route path="/marketplace" element={<Marketplace />} />
            <Route path="/store" element={<Store />} />
            <Route path="/collections/:_id" element={<Collections />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/kyc" element={<KYC />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="nftminting" element={<NFTMinting />} />
            <Route path="dao" element={<DAO />} />
            <Route path="stake" element={<Stake />} />
            <Route path="/collectiondetail/:_id" element={<CollectionDetail />} />
            <Route path="/mintingcollection/:_id" element={<MintingCollection />} />
            <Route path="/mapping" element={<Mapping />} />
            <Route path="/minting/:nftId" element={<Minting />} />

            <Route path="/adminlogin" element={<AdminLogin />} />
            <Route path="/approve" element={<Approve />} />
            <Route path="/blacklist" element={<Blacklist />} />
            <Route path="/approvekyc" element={<ApproveKYC />} />
          </Routes>
          {/* <Footer /> */}
        </BrowserRouter>
      </div>
    )
  }
}

const mapDispatchToProps = { logout, setAddress, getXdcPrice };

const mapStateToProps = ({ Auth }) => {
  let { auth } = Auth
  return { auth }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);