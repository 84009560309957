import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { Button, Modal} from 'react-bootstrap';
import { FileUploader } from "react-drag-drop-files";
import Slider from "react-slick";

const CollectionDetail = () => {

    let settings = {
        centerMode: true,
        centerPadding: '60px',
        slidesToShow: 1,
        arrows:false,
        dots:false,
      };

      const [show, setShow] = useState(false);
        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);

        const [file, setFile] = useState(null);
    const [fileImage, setFileImage] = useState(null);
    const handleChange = (file) => {
        setFile(file[0]);
    };

    const handleChangeImage = (file) => {
        setFileImage(file[0]);
    };
    return(
        <>
        <Header />
            <div className="main-wrapper">
                <div className="container">
                    <div className="main-head">
                        <h1>Collection Detail</h1>
                    </div> 

                    <div className="collection-detail">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="main-img-wrap">
                                    <img src="images/colleagues.png" alt="" />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="collection-main-info">
                                    <h2>Discuss with Colleagues</h2>

                                    <p>Adipiscing at in tellus integer. Pellentesque massa placerat duis ultricies lacus. Nisi porta lorem mollis aliquam ut porttitor leo. Venenatis cras..</p>

                                    <div class="price">
                                        <div class="price-card">
                                            <h5>Current Price</h5>
                                            <span><b>1 XDC</b></span>
                                        </div>
                                    </div>

                                    <div class="price">
                                        <div class="price-card">
                                            <h5>Set Price</h5>
                                            <input type="number" placeholder="Set Price 0.01" maxlength="5"/>
                                        </div>
                                    </div>

                                    <div className="btns">
                                        <a>
                                                <img src="images/bag.png" alt="" />
                                                    Buy
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default CollectionDetail;