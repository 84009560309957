import React from 'react';
import Slider from "react-slick";
import Header from '../Header';
import Footer from '../Footer';
const Home = () => {

    let settings = {
        centerMode: true,
        centerPadding: '60px',
        slidesToShow: 1,
        arrows:false,
        dots:false,
      };

    return(
        <>
        <Header />
            <section class="banner">
            <div class="container">
                <h1>Explore, Play, Socialize: Make Your Own Rules</h1>
                <p>Discover a world where exploration transforms into gameplay, and connections blossom naturally. You can craft your adventure and shape the rules in our Xverse, where simplicity meets limitless intrigue.</p>
                <div class="btns">
                    <a href="#">Get Started</a>
                    <a href="#" class="explore-btn">Explore Now</a>
                </div>
                <ul>
                    <li>
                        <h6>29K+</h6>
                        <span>Artworks</span>
                    </li>
                    <li>
                        <h6>18K+</h6>
                        <span>Artists</span>
                    </li>
                    <li>
                        <h6>25K+</h6>
                        <span>Auction</span>
                    </li>
                </ul>
            </div>
        </section>

        <section class="about">
            <div class="container">
                <div class="row justify-content-between align-items-center">
                    <div class="col-lg-5 mb-lg-0 mb-5">
                        <h2>Ignite Your Creativity in the <span>Xverse</span></h2>
                        <p>From developers pushing technological boundaries to gamers redefining virtual experiences, our virtual space is a hub of inspiration and discovery. Join the revolution and become part of the narrative that's transforming the digital landscape.</p>
                        <a href="#">Know More</a>
                    </div>
                    <div class="col-lg-5">
                        <img src="images/about.png" alt="" />
                    </div>
                </div>
            </div>
        </section>

        <section class="work">
            <div class="container">
                <div class="row title-row">
                    <div class="col-12 text-center">
                        <h2><span>How does the Xverse work</span></h2>
                        <p>Step into our Xverse – where you'll explore diverse landscapes, dive into thrilling games and connect with like-minded adventurers. Tailor your experience, set your rules, and embark on a journey where endless possibilities await your exploration, play, and connections.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="work-card">
                            <img src="images/colleagues.png" alt="" />
                            <h4>Discuss with Colleagues</h4>
                            <p>Working online is the same experience as work ing in an office. This will be. achieved
                                with..</p>
                            <div class="btns">
                                <a href="#">Try now</a>
                                <a href="#" class="explore-btn">Explore Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="work-card">
                            <img src="images/colleagues.png" alt="" />
                            <h4>Discuss with Colleagues</h4>
                            <p>Working online is the same experience as work ing in an office. This will be. achieved
                                with..</p>
                            <div class="btns">
                                <a href="#">Try now</a>
                                <a href="#" class="explore-btn">Explore Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="work-card">
                            <img src="images/colleagues.png" alt="" />
                            <h4>Discuss with Colleagues</h4>
                            <p>Working online is the same experience as work ing in an office. This will be. achieved
                                with..</p>
                            <div class="btns">
                                <a href="#">Try now</a>
                                <a href="#" class="explore-btn">Explore Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="work-card">
                            <img src="images/colleagues.png" alt="" />
                            <h4>Discuss with Colleagues</h4>
                            <p>Working online is the same experience as work ing in an office. This will be. achieved
                                with..</p>
                            <div class="btns">
                                <a href="#">Try now</a>
                                <a href="#" class="explore-btn">Explore Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="work-card">
                            <img src="images/colleagues.png" alt="" />
                            <h4>Discuss with Colleagues</h4>
                            <p>Working online is the same experience as work ing in an office. This will be. achieved
                                with..</p>
                            <div class="btns">
                                <a href="#">Try now</a>
                                <a href="#" class="explore-btn">Explore Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="work-card">
                            <img src="images/colleagues.png" alt="" />
                            <h4>Discuss with Colleagues</h4>
                            <p>Working online is the same experience as work ing in an office. This will be. achieved
                                with..</p>
                            <div class="btns">
                                <a href="#">Try now</a>
                                <a href="#" class="explore-btn">Explore Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="marketplace">
            <div class="container">
                <div class="row justify-content-between align-items-center">
                    <div class="col-lg-5 mb-lg-0 mb-5">
                        <h2>Make a statement – your journey, your style</h2>
                        <p>Get into a realm of personal expression with our exclusive accessories. Craft your virtual identity with unique avatars, design your dream spaces, and level up your experience in the Xverse. Make a statement – your journey, your style.</p>
                        <a href="#">Know More</a>
                    </div>
                    <div class="col-lg-5">
                        <img src="images/marketplace.png" alt="" />
                    </div>
                </div>
            </div>
        </section>

        <section class="projects">
            <div class="container">
                <div class="row title-row">
                    <div class="col-12 text-center">
                        <h2><span>Our Latest 3D Projects</span></h2>
                        <p>Dive into the forefront of creativity with our latest 3D projects. You can explore a dynamic showcase of immersive experiences and discover the forefront of virtual innovation. </p>
                    </div>
                </div>
                <div class="row detail-row">
                    <div class="col-lg-4 col-md-6">
                        <div class="project-card">
                            <img src="images/project-1.png" alt="" />
                            <h4>3D Buildings</h4>
                            <p>In the past, works of art such as paintings could only be seen in the real world..</p>
                            <div class="btns">
                                <a href="#" class="explore-btn">Explore now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="project-card">
                            <img src="images/project-1.png" alt="" />
                            <h4>3D Buildings</h4>
                            <p>In the past, works of art such as paintings could only be seen in the real world..</p>
                            <div class="btns">
                                <a href="#" class="explore-btn">Explore now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="project-card">
                            <img src="images/project-1.png" alt="" />
                            <h4>3D Buildings</h4>
                            <p>In the past, works of art such as paintings could only be seen in the real world..</p>
                            <div class="btns">
                                <a href="#" class="explore-btn">Explore now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="activity">
            <div class="container">
                <div class="row title-row">
                    <div class="col-12 text-center">
                        <h2><span>Our Latest Activity</span></h2>
                        <p>Stay in the pulse of the Xverse with our latest activity feed. Explore real-time updates, events, and community engagements as the virtual world unfolds before you.</p>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="activity-slider">
                <Slider {...settings}>
                    <div>
                        <div class="item">
                            <div class="video-btn">
                                <img src="images/video-btn.png" alt="" />
                            </div>
                            <div class="content">
                                <h3>Xverse Fashion Week</h3>
                            </div>
                            <div class="thumbnail">
                                <img src="images/slider-img1.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="item">
                            <div class="video-btn">
                                <img src="images/video-btn.png" alt="" />
                            </div>
                            <div class="content">
                                <h3>Xverse Fashion Week</h3>
                            </div>
                            <div class="thumbnail">
                                <img src="images/slider-img1.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="item">
                            <div class="video-btn">
                                <img src="images/video-btn.png" alt="" />
                            </div>
                            <div class="content">
                                <h3>Xverse Fashion Week</h3>
                            </div>
                            <div class="thumbnail">
                                <img src="images/slider-img1.png" alt="" />
                            </div>
                        </div>
                    </div>
                    </Slider>
                </div>
            </div>
        </section>

        <section class="research">
            <div class="container">
                <div class="row title-row">
                    <div class="col-12 text-center">
                        <h2><span>Research With ChatGPT</span></h2>
                        <p>Dive into our Free Research Preview, where AI meets simplicity. Explore a natural and safe interaction with ChatGPT, reshaping the way you discover information in our Xverse.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="detail-row">
                            <form>
                                <textarea
                                    value="Free Research Preview. Our goal is to make AI systems more natural and safe to interact with....................."></textarea>
                                <button type="submit">Get Answer</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </>
    );
};

export default Home;