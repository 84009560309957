import moment from "moment";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { React, useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Modal } from 'react-bootstrap';

import Header from '../TopBar';
import MainLinks from '../MainLinks';
import { web3 } from "../../store/web3";
import { toFixed } from "../../store/numberFormat";
import { makeTokens } from "../../store/contract/index";
import { setLoader, getProposals, addProposal, sendEmailDao } from "../../store/actions/Auth";

const logo = "/images/main-logo.png";
const voted = "/images/voted.png";
const rejected = "/images/rejected.png";
const sidebararrow = "/images/sidebar-arrow.png";

const DAO = (props) => {
    const onOpen = () => SetOPen(true);
    const [Open, SetOPen] = useState(false);
    const [isActive, setIsActive] = useState(false);
    
    const sidebarArrow = () => {
        setIsActive(!isActive);
    };
    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);

    const navigate = useNavigate();

    let [dEnd, setdEnd] = useState("");
    let [daoData, setDaoData] = useState({});
    let [modalData, setModalData] = useState({});
    let [proposalObj, setProposalObj] = useState({});
    let [proposalTitle, setproposalTitle] = useState("");
    let [proposalDetails, setproposalDetails] = useState([]);
    let [proposalDescription, setproposalDescription] = useState("");

    const [model, setModel] = useState(true);

    useEffect(() => {
        proposalPopulate();
    }, []);

    async function proposalPopulate() {
        const { DAOAddress } = await makeTokens();
        props.getProposals({ daoAddress: DAOAddress.toLowerCase() });
    }

    useEffect(() => {
        if (props.publicAddress == null || props.publicAddress == undefined) {
            EventBus.publish("error", `Please connect your wallet!`);
            return;
        } else {
            setDaoList();
        }
    }, [props.publicAddress, props.chain]);

    async function setDaoList() {
        let deployer = (await web3.currentProvider.enable())[0];
        const { DAO, GovernorNFT, XVRToken } = await makeTokens();
        let holding1 = await GovernorNFT.methods.balanceOf(deployer, 1).call({ from: deployer });
        let holding4 = await XVRToken.methods.balanceOf(deployer).call({ from: deployer });
        holding4 = web3.utils.fromWei(holding4, 'ether');
        let daoOwner = await DAO.methods.owner().call({ from: deployer });
        let proposalHolding = parseInt(holding1);
        let tokenHolding = parseInt(holding4);
        setDaoData({ daoOwner, proposalHolding, tokenHolding });
    }

    useEffect(() => {
        if (props.publicAddress == null || props.publicAddress == undefined) {
            return;
        } else {
            proposalsPopulate();
        }
    }, []);

    async function proposalsPopulate() {
        let array1 = [];
        const { DAO } = await makeTokens();
        let result1 = await DAO.methods.numProposals().call({ from: props.publicAddress });
        if (parseInt(result1) !== 0) {
            for (let i = 0; i < result1; i++) {
                let result2 = await DAO.methods.proposals(i).call({ from: props.publicAddress });
                array1.push(result2);
            }
        }
        setproposalDetails(array1);
    }

    async function handleOnInput(e) {
        const waitFor = (delay) =>
            new Promise((resolve) => setTimeout(resolve, delay));
        if ([e.target.name] == "proposalTitle") {
            setproposalTitle(e.target.value);
        } else if ([e.target.name] == "proposalDescription") {
            setproposalDescription(e.target.value);
        } else if ([e.target.name] == "dEnd") {
            setdEnd(e.target.value);
        }
    }

    async function createProposal(e) {
        try {

            e.preventDefault();

            const waitFor = (delay) =>
                new Promise((resolve) => setTimeout(resolve, delay));

            let { publicAddress } = props;

            if (proposalTitle == "") {
                EventBus.publish("error", `Please enter proposal title`);
                return;
            }

            if (!proposalTitle.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter proposal title`);
                return;
            }

            if (!proposalTitle.match(/[a-zA-Z]/)) {
                EventBus.publish("error", `Proposal title must contain alphabets`);
                return;
            }

            if (proposalDescription == "") {
                EventBus.publish("error", `Please enter proposal description`);
                return;
            }

            if (!proposalDescription.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter proposal description`);
                return;
            }

            if (!proposalDescription.match(/[a-zA-Z]/)) {
                EventBus.publish("error", `Proposal description must contain alphabets`);
                return;
            }

            if (dEnd == "") {
                EventBus.publish("error", `Please enter proposal deadline`);
                return;
            }

            let deployer = (await web3.currentProvider.enable())[0];

            let from = publicAddress;
            let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
            if (output == "xdc") {
                from = "0x" + publicAddress.substring(3);
            } else {
                from = publicAddress;
            }

            const { DAOAddress, DAO } = await makeTokens();

            let deadline = parseInt((new Date(dEnd).getTime()) / 1000);

            props.setLoader({
                message: "Adding Proposal...",
                status: true,
            });

            await web3.eth
                .sendTransaction({
                    from: deployer,
                    to: DAOAddress,
                    gas: 5000000,
                    data: DAO.methods
                        .createProposal(proposalTitle, proposalTitle, deadline)
                        .encodeABI(),
                })
                .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
                .on('receipt', async receipt => {
                    let proposal = {
                        proposalTitle,
                        proposalDescription,
                        deadline
                    };
                    await props.addProposal({ daoAddress: DAOAddress, proposal });
                    props.setLoader({ status: false });

                    props.getProposals({ daoAddress: DAOAddress });
                    proposalsPopulate();
                    setDaoList();
                    setShow(false);

                    EventBus.publish("success", `Proposal Created Successfully!`);
                    await waitFor(2500);
                    setdEnd("");
                    setproposalTitle("");
                    setproposalDescription("");
                });
        } catch (e) {
            console.log(e);
            setShow(false);
            props.setLoader({
                message: "Transfer Not Completed...",
                status: false,
            });
            EventBus.publish("error", `Unable To add proposal`);
        }
    }

    async function vote(index) {
        try {

            let { publicAddress } = props;

            let deployer = (await web3.currentProvider.enable())[0];

            let tokenAmount = web3.utils.toWei("1", 'ether');

            let from = publicAddress;
            let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
            if (output == "xdc") {
                from = "0x" + publicAddress.substring(3);
            } else {
                from = publicAddress;
            }

            const { DAOAddress, DAO, XVRAddress, XVRToken } = await makeTokens();

            let userBalance = await XVRToken.methods.balanceOf(deployer).call();
            userBalance = web3.utils.fromWei(userBalance, 'ether');

            if (parseFloat(userBalance) < 1) return EventBus.publish("error", `Insufficient token amount`);
            props.setLoader({
                message: "XVR Approval in Progress...",
                status: true,
            });

            await XVRToken.methods.approve(DAOAddress, tokenAmount.toString()).send({
                from: deployer,
            });
            props.setLoader({
                message: "Transferring XVR Token...",
                status: true,
            });
            await web3.eth
                .sendTransaction({
                    from: deployer,
                    to: XVRAddress,
                    gas: 5000000,
                    data: XVRToken.methods
                        .transfer(DAOAddress, tokenAmount)
                        .encodeABI(),
                })
                .on('transactionHash', hash => console.log(`************** deploy dao contract hash = ${hash}`))
                .on('receipt', async receipt => {
                    props.setLoader({
                        message: "Voting...",
                        status: true,
                    });

                    await web3.eth
                        .sendTransaction({
                            from: deployer,
                            to: DAOAddress,
                            gas: 5000000,
                            data: DAO.methods
                                .voteOnProposal(index, true)
                                .encodeABI(),
                        })
                        .on('transactionHash', hash => console.log(`************** deploy dao contract hash = ${hash}`))
                        .on('receipt', async receipt => {
                            props.setLoader({ status: false });
                            proposalsPopulate();
                            EventBus.publish("success", `Voted Successfully!`);
                        });
                });
        } catch (e) {
            console.log(e);
            props.setLoader({
                message: "Could not vote...",
                status: false,
            });
            EventBus.publish("error", `Could not vote`);
        }
    }

    async function against(index) {
        try {

            let { publicAddress } = props;

            let deployer = (await web3.currentProvider.enable())[0];

            let from = publicAddress;
            let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
            if (output == "xdc") {
                from = "0x" + publicAddress.substring(3);
            } else {
                from = publicAddress;
            }

            let tokenAmount = web3.utils.toWei("1", 'ether');

            const { DAOAddress, DAO, XVRAddress, XVRToken } = await makeTokens();

            let userBalance = await XVRToken.methods.balanceOf(deployer).call();
            userBalance = web3.utils.fromWei(userBalance, 'ether');

            if (parseFloat(userBalance) < 1) return EventBus.publish("error", `Insufficient token amount`);
            props.setLoader({
                message: "XVR Approval in Progress...",
                status: true,
            });

            await XVRToken.methods.approve(DAOAddress, tokenAmount.toString()).send({
                from: deployer,
            });
            props.setLoader({
                message: "Transferring XVR Token...",
                status: true,
            });
            await web3.eth
                .sendTransaction({
                    from: deployer,
                    to: XVRAddress,
                    gas: 5000000,
                    data: XVRToken.methods
                        .transfer(DAOAddress, tokenAmount)
                        .encodeABI(),
                })
                .on('transactionHash', hash => console.log(`************** deploy dao contract hash = ${hash}`))
                .on('receipt', async receipt => {
                    props.setLoader({
                        message: "Voting...",
                        status: true,
                    });

                    await web3.eth
                        .sendTransaction({
                            from: deployer,
                            to: DAOAddress,
                            gas: 5000000,
                            data: DAO.methods
                                .voteOnProposal(index, false)
                                .encodeABI(),
                        })
                        .on('transactionHash', hash => console.log(`************** deploy dao contract hash = ${hash}`))
                        .on('receipt', async receipt => {
                            props.setLoader({ status: false });
                            proposalsPopulate();
                            EventBus.publish("success", `Voted Successfully!`);
                        });
                });
        } catch (e) {
            console.log(e);
            props.setLoader({
                message: "Could not vote...",
                status: false,
            });
            EventBus.publish("error", `Could not vote`);
        }
    }

    async function execute(index) {
        try {
            await setDaoList();

            props.setLoader({
                message: "Loading...",
                status: true,
            });

            setProposalObj({ ...props.proposalList[index], ...proposalDetails[index] });

            setModalData({ ...props.proposalList[index], ...proposalDetails[index] });

            props.setLoader({
                message: "Loading...",
                status: false,
            });

            setShow1(true);
        } catch (e) {
            console.log(e);
            props.setLoader({
                message: "Loading...",
                status: false,
            });
            EventBus.publish("error", `Could not execute`);
        }
    }

    async function executeProposal() {
        try {

            let deployer = (await web3.currentProvider.enable())[0];

            const { DAOAddress, DAO } = await makeTokens();

            if (parseInt(modalData?.['deadline']) > parseInt(Date.now() / 1000)) {
                EventBus.publish("error", `Proposal voting not ended yet!`);
                return;
            }

            props.setLoader({
                message: "Executing...",
                status: true,
            });

            let index = parseInt(modalData['id']) - 1;

            await web3.eth
                .sendTransaction({
                    from: deployer,
                    to: DAOAddress,
                    gas: 5000000,
                    data: DAO.methods
                        .executeProposal(parseInt(index), 1)
                        .encodeABI(),
                })
                .on('transactionHash', hash => console.log(`************** deploy dao contract hash = ${hash}`))
                .on('receipt', async receipt => {
                    props.setLoader({ status: false });
                    // props.sendEmailDao({ daoAddress: daoAddress, proposalTitle: proposalObj['proposalTitle'], proposalDescription: proposalObj['proposalDescription'] });
                    proposalsPopulate();
                    setShow1(false);
                    EventBus.publish("success", `Executed Successfully!`);
                });
        } catch (e) {
            console.log(e);
            setShow1(false);
            props.setLoader({
                message: "Loading...",
                status: false,
            });
            EventBus.publish("error", `Could not execute`);
        }
    }

    async function rejectProposal() {
        try {

            let deployer = (await web3.currentProvider.enable())[0];

            const { DAOAddress, DAO } = await makeTokens();

            if (parseInt(modalData?.['deadline']) > parseInt(Date.now() / 1000)) {
                EventBus.publish("error", `Proposal voting not ended yet!`);
                return;
            }

            props.setLoader({
                message: "Rejecting ...",
                status: true,
            });

            let index = parseInt(modalData['id']) - 1;

            await web3.eth
                .sendTransaction({
                    from: deployer,
                    to: DAOAddress,
                    gas: 5000000,
                    data: DAO.methods
                        .executeProposal(parseInt(index), 2)
                        .encodeABI(),
                })
                .on('transactionHash', hash => console.log(`************** deploy dao contract hash = ${hash}`))
                .on('receipt', async receipt => {
                    props.setLoader({ status: false });
                    proposalsPopulate();
                    setShow1(false);
                    EventBus.publish("success", `Executed Successfully!`);
                });

        } catch (e) {
            console.log(e);
            setShow1(false);
            props.setLoader({
                message: "Loading...",
                status: false,
            });
            EventBus.publish("error", `Could not execute`);
        }
    }

    return (
        <>
            <div className="account sidebar">
                <div className= {isActive ? 'left open' : 'left'}>
                    <div className='inner'>
                        <Link className="logo" to="/">
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>

                    <MainLinks />
                </div>

                <div className={isActive ? 'bg-open active' : 'bg-open'}>
                </div>

                <button className={isActive ? 'sidebar-arrow active' : 'sidebar-arrow'} onClick={sidebarArrow}>
                    <img src={sidebararrow} />
                </button>

                <div className="right">
                    <Header />

                    <div className="detail-row dao-proposal">
                        <div className='container'>
                            <div className="inner">
                                <div className="filters">
                                    <Form>
                                        {/* <Form.Group className="form-group" controlId="formSearch">
                                            <Form.Control type="search" placeholder="Search" />
                                        </Form.Group> */}

                                        {
                                            (Object.keys(daoData).length > 0 && daoData['daoOwner'] && daoData['daoOwner'].toLowerCase().includes(props.publicAddress.toLowerCase())) ||
                                                (Object.keys(daoData).length > 0 && daoData['proposalHolding'] && daoData['proposalHolding'] > 1) ?
                                                <Button onClick={handleShow} className="common-btn">
                                                    Create a proposal
                                                </Button>
                                                : <></>
                                        }
                                    </Form>
                                </div>

                                <div className="table-wrapper proposals-table">
                                    <table responsive="md">
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Expiry date</th>
                                                <th>Status</th>
                                                <th>Vote count</th>
                                                <th>Vote/Reject</th>
                                                {
                                                    (Object.keys(daoData).length > 0 && daoData['daoOwner'] && daoData['daoOwner'].toLowerCase().includes(props.publicAddress.toLowerCase()) == true) ?
                                                        <th>Action</th>
                                                        :
                                                        <></>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                proposalDetails?.length > 0 &&
                                                proposalDetails?.map((item, index) => (
                                                    <tr>
                                                        <td>{item?.title}</td>
                                                        <td>{moment(Number(item?.deadline) * 1000).format('L hh:mm:ss A')}</td>
                                                        {
                                                            (Date.now() / 1000) < parseInt(item?.deadline)
                                                                ?
                                                                <td className="status green">Active</td>
                                                                :
                                                                <td className="status">Expired</td>
                                                        }
                                                        <td class="vote-count">
                                                            <p><img src={voted} alt="" />
                                                                {
                                                                    parseInt(item?.voteCount) > 0 ?
                                                                        toFixed(((parseInt(item?.yayVotes) / parseInt(item?.voteCount)) * 100), 'price')
                                                                        : 0
                                                                }% voted
                                                            </p>
                                                            <p><img src={rejected} alt="" />
                                                                {
                                                                    parseInt(item?.voteCount) > 0 ?
                                                                        toFixed(((parseInt(item?.nayVotes) / parseInt(item?.voteCount)) * 100), 'price')
                                                                        : 0
                                                                }% rejected
                                                            </p>
                                                        </td>
                                                        <td class="vote-reject">
                                                            <p class="vote">
                                                                {
                                                                    (Date.now() / 1000) < parseInt(item?.deadline) ?
                                                                        <button className="common-btn" onClick={() => vote(index)}>Vote</button> :
                                                                        <button className="common-btn" disabled>Vote</button>
                                                                }
                                                            </p>
                                                            <p class="reject">
                                                                {
                                                                    (Date.now() / 1000) < parseInt(item?.deadline) ?
                                                                        <button className="common-btn" onClick={() => against(index)}>Reject</button> :
                                                                        <button className="common-btn" disabled>Reject</button>
                                                                }
                                                            </p>
                                                        </td>
                                                        {
                                                            (Object.keys(daoData).length > 0 && daoData['daoOwner'] && daoData['daoOwner'].toLowerCase().includes(props.publicAddress.toLowerCase())) ?
                                                                <td class="execute">
                                                                    <button onClick={() => execute(index)} className="common-btn">Execute</button>
                                                                </td>
                                                                :
                                                                <></>
                                                        }
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal className="create-proposal text-center" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <h2>New Proposal</h2>
                </Modal.Header>
                <Modal.Body>
                    <p>Submit your proposals and let your innovative ideas and strategies drive the evolution of the Xverse.</p>

                    <form onSubmit={createProposal}>
                        <input
                            type="text"
                            name='proposalTitle'
                            value={proposalTitle}
                            onChange={handleOnInput}
                            placeholder="Proposal Title"
                        />
                        <textarea
                            as="textarea"
                            rows={6}
                            name='proposalDescription'
                            value={proposalDescription}
                            onChange={handleOnInput}
                            placeholder="Proposal details"
                        />
                        <input
                            type="datetime-local"
                            placeholder="End date"
                            name="dEnd"
                            value={dEnd}
                            onInput={handleOnInput}
                        />
                        <button type='submit'>Submit proposal</button>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal className="execute-proposal text-center" show={show1} onHide={handleClose1}>
                {
                    Object.keys(modalData).length > 0 &&
                    <>
                        <Modal.Header closeButton>
                            <h2>{modalData?.['title']}</h2>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{modalData?.['description']}</p>

                            {
                                daoData['daoOwner'].toLowerCase() == props.publicAddress.toLowerCase() &&
                                modalData['executed'] == true &&
                                (<p>Decision Made</p>)
                            }

                            {
                                daoData['daoOwner'].toLowerCase() == props.publicAddress.toLowerCase() &&
                                parseInt(modalData['yayVotes']) == 0 && parseInt(modalData['nayVotes']) == 0 && modalData['executed'] == false &&
                                (<p>No votes submitted</p>)
                            }

                            {
                                daoData['daoOwner'].toLowerCase() == props.publicAddress.toLowerCase() &&
                                parseInt(modalData['yayVotes']) > parseInt(modalData['nayVotes']) && modalData['executed'] == false &&
                                (
                                    <button className="common-btn" onClick={(event) => {
                                        event.preventDefault();
                                        executeProposal();
                                    }}>Execute Proposal</button>
                                )
                            }

                            {
                                daoData['daoOwner'].toLowerCase() == props.publicAddress.toLowerCase() &&
                                parseInt(modalData['yayVotes']) < parseInt(modalData['nayVotes']) && modalData['executed'] == false &&
                                (
                                    <button className="common-btn" onClick={(event) => {
                                        event.preventDefault();
                                        rejectProposal();
                                    }}>Reject Proposal</button>
                                )
                            }
                        </Modal.Body>
                    </>
                }
            </Modal>
        </>
    );
};

const mapDispatchToProps = {
    setLoader,
    getProposals,
    addProposal,
    sendEmailDao
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, proposalList, chain } = Auth;
    return { publicAddress, proposalList, chain }
};

export default connect(mapStateToProps, mapDispatchToProps)(DAO);